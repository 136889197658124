import currentPairState from "states/currentPair";

const Chart = () => {
  const { currentPair } = currentPairState();

  return (
    <div className="flex flex-col h-full">
      <iframe
        className="h-full"
        id="geckoterminal-embed"
        title="GeckoTerminal Embed"
        src={`https://dexscreener.com/${currentPair.chain}/${currentPair.pairAddress}?embed=1&theme=dark&info=0`}
        // src={`https://www.geckoterminal.com/${currentPair.chain}/pools/${currentPair.pairAddress}?embed=1&info=0&swaps=1`}
        allow="clipboard-write"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Chart;

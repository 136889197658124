import axios from "axios";

import { getToken } from "../service/storage";

const http = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_URL_BASE,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    timeout: 60000,
  });

  instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  });

  return instance;
};

export default http;

import useLogin from "hooks/useLogin";
import { useEffect, useState } from "react";
import toast, { ToastOptions } from "react-hot-toast";

type LoginProps = {
  showLoginModal: boolean;
  setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const toastOption: ToastOptions = { duration: 2000, position: "top-right" };

const Login = ({ showLoginModal, setShowLoginModal }: LoginProps) => {
  const [code, setCode] = useState("");
  const { isLoading, isError, handleLogin } = useLogin();

  const disabledButtonStyle =
    code === "" || isLoading
      ? " bg-textBrown hover:bg-textBrown cursor-not-allowed"
      : "";

  useEffect(() => {
    if (isError === true) {
      setCode("");
      toast.error("Invalid access code", toastOption);
    } else if (isError === false) {
      setShowLoginModal(false);
      toast.success("Logged in successfully!", toastOption);
    }
  }, [isError, setShowLoginModal]);

  if (!showLoginModal) return null;

  return (
    <div
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full text-black backdrop-blur-sm"
      onClick={() => setShowLoginModal(false)}
    >
      <div
        className="relative p-4 w-full max-w-2xl max-h-full"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="text-white text-center">
          Please connect to your Telegram account using the "Leaderboard Access
          Code" button from the Telegram bot homepage.
        </p>
        <div className="flex flex-col md:flex-row items-center">
          <input
            className="text-sm rounded-lg w-full h-12 p-2 text-center"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Please input the access code"
          />
          <div className="flex items-center p-4 md:p-5 rounded-b">
            <button
              onClick={() => handleLogin(code)}
              className={
                "text-white bg-blue font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer" +
                disabledButtonStyle
              }
              disabled={code === ""}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

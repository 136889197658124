import Tooltip from "components/Tooltip";
import { UserStatistics } from "states/user";
import { BADGE_DESCRIPTION, getHighestBadge } from "utils/badges";

type LeaderboardTableProps = {
  userStatisticsList: UserStatistics[];
  currentUser: bigint;
};
const LeaderboardTable = ({
  userStatisticsList,
  currentUser,
}: LeaderboardTableProps) => {
  return (
    <div className="relative h-full overflow-y-auto flex">
      <table className="text-sm text-left mx-6 flex-1">
        <thead className="text-md uppercase sticky top-0 z-10 bg-secondary text-base text-gray font-bold font-transducer">
          <tr className="text-center">
            <th scope="col" className="md:px-6 py-3">
              Rank
            </th>
            <th scope="col" className="md:px-6 py-3">
              User
            </th>
            <th scope="col" className="md:px-6 py-3">
              POINTS
            </th>
            <th scope="col" className="md:px-6 py-3">
              Badge
            </th>
          </tr>
        </thead>
        <tbody>
          {userStatisticsList.map((data) => {
            const highestBadge = getHighestBadge(data);
            return (
              <tr
                key={data.user_id}
                className={
                  "border-b border-gray font-transducer text-center font-bold" +
                  (data.user_id === currentUser ? " text-orange" : "")
                }
              >
                <td className="md:px-6 py-3">{data.rank}</td>
                <td className="md:px-6 py-3">{data.alias || data.full_name}</td>
                <td className="md:px-6 py-3">
                  {data.trading_point.toLocaleString()}
                </td>
                <td className="md:px-6 py-3 flex justify-center">
                  <Tooltip
                    title={
                      highestBadge.badveLevel === "empty"
                        ? ""
                        : `${
                            BADGE_DESCRIPTION[highestBadge.badgeName].name
                          } : ${highestBadge.badveLevel}`
                    }
                    tooltipStyle="w-max"
                  >
                    <img
                      src={`badges/${highestBadge.badgeName}_${highestBadge.badveLevel}.png`}
                      alt={highestBadge.badgeName}
                      className="w-8 h-8"
                    />
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;

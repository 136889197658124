import { atom } from "recoil";
import { useRecoilState } from "recoil";

interface AuthInfo {
  isLoggedIn: boolean;
}

const authInfoState = atom<AuthInfo>({
  key: "auth-info",
  default: {
    isLoggedIn: false,
  },
});

export default function State() {
  const [authInfo, setAuthInfo] = useRecoilState(authInfoState);
  return { authInfo, setAuthInfo };
}

const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <div className="w-full bg-darkOrange rounded-full h-2.5">
      <div
        className="bg-orange h-2.5 rounded-full"
        style={{ width: percentage + "%" }}
      />
    </div>
  );
};
export default ProgressBar;

import { formatEther, formatUnits } from "ethers";
import moment from "moment";

export const formatEtherFixed = (amount: bigint, decimals = 3): string => {
  return parseFloat(formatEther(amount)).toFixed(decimals);
};

export const formatTokenUSDValue = (
  amount: bigint,
  usdPrice: number,
  tokenDecimal = 18,
  decimals = 3
): string => {
  return (parseFloat(formatUnits(amount, tokenDecimal)) * usdPrice).toFixed(
    decimals
  );
};

export const formatBalance = (
  amount: bigint,
  tokenDecimal = 18,
  showDecimals = 3
): string => {
  return (
    Math.floor(
      parseFloat(formatUnits(amount, tokenDecimal)) * 10 ** showDecimals
    ) /
    10 ** showDecimals
  ).toString();
};

export const toHex = (num: number | bigint): string => {
  return "0x" + num.toString(16);
};

export const formatPrice = (number: number): string => {
  if (!number) return "-";
  if (number > 1) return number.toFixed(4);
  return number.toFixed(30).match(/^-?\d*\.?0*\d{0,4}/)[0];
};

export const formatBigNumber = (number: number, decimals = 3): string => {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(decimals) + "B";
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(decimals) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(decimals) + "K";
  } else {
    return number.toFixed(decimals);
  }
};

export const formatPercent = (number: number, decimals = 3): string => {
  return number.toFixed(decimals);
};

export const formatOrderType = (orderType: string, type: string): string => {
  switch (orderType) {
    case "manual_buy":
      return "BUY";
    case "manual_sell":
      return "SELL";
    case "snipe":
      return "SNIPE";
    case "limit_order":
      if (type === "buy") {
        return "LIMIT BUY";
      } else {
        return "LIMIT SELL";
      }
    case "limit_sell":
      return "LIMIT SELL";
    case "anti_rug":
      return "ANTI RUG";
    default:
      return "BUY";
  }
};

export const formatTime = (time: Date, diffTime: number): string => {
  return moment.utc(time).add(diffTime, "hours").format("YYYY-MM-DD HH:mm:ss");
};

export const formatTimeWithTimezone = (
  time: Date,
  diffTime: number
): string => {
  return `${formatTime(time, diffTime)} (UTC${
    diffTime === 0 ? "" : diffTime > 0 ? "+" + diffTime : diffTime
  })`;
};

export const formatDuration = (
  timeDiff: number // in milliseconds
): string => {
  const days: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours: number = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes: number = Math.floor(
    (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
  );

  return `${days}d ${hours}h ${minutes}m`;
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLogin from "hooks/useLogin";
import { getToken } from "service/storage";
import authInfoState from "states/auth";
import ROUTES from "config/routes";

const ProtectedRoute = (props: any) => {
  const navigate = useNavigate();
  const [searchParams, setSearchparams] = useSearchParams();
  const { setAuthInfo } = authInfoState();
  const { handleLogin } = useLogin();
  const accessCode = searchParams.get("code");

  useEffect(() => {
    (async () => {
      if (accessCode) {
        await handleLogin(accessCode);
        searchParams.delete("code");
        setSearchparams(searchParams);
      }

      const userToken = getToken();
      if (!userToken || userToken === "undefined") {
        setAuthInfo({ isLoggedIn: false });
        return navigate(ROUTES.LEADERBOARD.path);
      }
      setAuthInfo({ isLoggedIn: true });
    })();
  }, []);

  return props.children;
};

export default ProtectedRoute;

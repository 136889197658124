import styled from "styled-components";
import { useState, useEffect } from "react";
import { formatEther } from "ethers";
import { cancelLimitOrder, getLimitOrders } from "apis/limitOrder";
import { cancelSnipeOrder, getSnipeOrders } from "apis/snipeOrder";
import appInfoState from "states/appInfo";
import {
  formatBalance,
  formatBigNumber,
  formatDuration,
  formatPrice,
} from "utils/format";
import colors from "theme/colors";
import { FEE_PERCENT_10000 } from "config/constants";

const Styled = {
  Actions: styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid linear-gradient(0deg, #33251e 0%, #33251e 100%), #f1651d;
  `,
  Action: styled.div<{ active: boolean }>`
    color: ${colors.white};
    font-weight: 700;
    cursor: pointer;
    border: 1px solid #54301e;
    text-transform: uppercase;
    background: linear-gradient(0deg, #33251e 0%, #33251e 100%), #f1651d;
    padding: 8px;
    font-size: 10px;
    ${({ active }: { active: boolean }) =>
      active &&
      `
      background: #F1651D;
      `};

    &:first-child {
      border-radius: 7.5px 0px 0px 7.5px;
    }

    &:last-child {
      border-radius: 0px 7.5px 7.5px 0px;
    }
  `,
  TableWrapper: styled.div`
    max-height: 250px;
    overflow-y: auto;
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
  `,
  Th: styled.th`
    color: ${colors.gray};
    font-size: 10px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    font-family: "Transducer Test";
  `,
  Td: styled.td<{ type?: string }>`
    color: ${({ type }: { type?: string }) =>
      type === "buy"
        ? colors.greenAlpha
        : type === "sell"
        ? colors.redAlpha
        : colors.white};
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid ${colors.transGray};
    padding: 15px 20px 15px 0;
    font-family: "Transducer Test";
  `,
  Button: styled.button`
    background: ${colors.transGray};
    padding: 4px;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "Transducer Test";
    font-weight: 700;
  `,
};

const getTriggerPrice = (order: any) => {
  return order.is_sell
    ? (((order.amount_out * 10000) / (10000 - FEE_PERCENT_10000)) *
        10 ** order.decimals) /
        10 ** 18 /
        order.amount_in
    : (order.amount_in * 10 ** order.decimals) /
        10 ** 18 /
        ((order.amount_out * 10000) / (10000 - FEE_PERCENT_10000));
};

type ActionsProps = {
  tab: number;
  setTab: (tab: number) => void;
};

const Actions = ({ tab, setTab }: ActionsProps) => {
  return (
    <Styled.Actions>
      <Styled.Action active={tab === 0} onClick={() => setTab(0)}>
        Limit
      </Styled.Action>
      <Styled.Action active={tab === 1} onClick={() => setTab(1)}>
        Snipe
      </Styled.Action>
    </Styled.Actions>
  );
};

const LimitOrders = (props: ActionsProps) => {
  const { appInfo } = appInfoState();
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    getLimitOrders().then((res) => {
      setOrders(res.data);
    });
  }, []);

  const handleCancel = async (id: number) => {
    await cancelLimitOrder({ id });
  };
  return (
    <>
      <Styled.TableWrapper>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.Th>Order Number</Styled.Th>
              <Styled.Th>Token</Styled.Th>
              <Styled.Th>Order Type</Styled.Th>
              <Styled.Th>Size</Styled.Th>
              <Styled.Th>Trigger Price</Styled.Th>
              <Styled.Th>Wallets</Styled.Th>
              <Styled.Th>Expires in</Styled.Th>
              <Styled.Th>
                <Actions {...props} />
              </Styled.Th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              const duration =
                new Date(order.expires_at).getTime() - Date.now();

              return (
                <tr key={order.id}>
                  <Styled.Td>{order.id}</Styled.Td>
                  <Styled.Td>{order.symbol}</Styled.Td>
                  <Styled.Td type={order.is_sell ? "sell" : "buy"}>
                    {order.is_sell ? "Sell" : "Buy"}
                  </Styled.Td>
                  <Styled.Td>
                    Ξ{" "}
                    {formatBalance(
                      order.is_sell ? order.amount_out : order.amount_in
                    )}
                  </Styled.Td>
                  <Styled.Td>
                    ${formatPrice(getTriggerPrice(order) * appInfo.etherPrice)}
                  </Styled.Td>
                  <Styled.Td>W{order.wallet_indexes}</Styled.Td>
                  <Styled.Td type={duration < 0 ? "sell" : ""}>
                    {duration < 0 ? "Expired" : formatDuration(duration)}
                  </Styled.Td>
                  <Styled.Td>
                    <Styled.Button onClick={() => handleCancel(order.id)}>
                      cancel
                    </Styled.Button>
                  </Styled.Td>
                </tr>
              );
            })}
          </tbody>
        </Styled.Table>
      </Styled.TableWrapper>
    </>
  );
};
const SnipeOrders = (props: ActionsProps) => {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    getSnipeOrders().then((res) => {
      setOrders(res.data);
      console.log(res.data);
    });
  }, []);
  const handleCancel = async (id: number) => {
    await cancelSnipeOrder({ id });
  };
  return (
    <>
      <Styled.TableWrapper>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.Th>Order Number</Styled.Th>
              <Styled.Th>Token</Styled.Th>
              <Styled.Th>Size</Styled.Th>
              <Styled.Th>Max MC</Styled.Th>
              <Styled.Th>Tip</Styled.Th>
              <Styled.Th>Wallets</Styled.Th>
              <Styled.Th>First Bundle</Styled.Th>
              <Styled.Th>
                <Actions {...props} />
              </Styled.Th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <Styled.Td>{order.id}</Styled.Td>
                <Styled.Td>{order.symbol}</Styled.Td>
                <Styled.Td>Ξ {formatEther(order.amount_in)}</Styled.Td>
                <Styled.Td>Ξ {formatBigNumber(order.max_marketcap)}</Styled.Td>
                <Styled.Td>Ξ {formatEther(order.priority_tip)}</Styled.Td>
                <Styled.Td>{order.wallet_indexes}</Styled.Td>
                <Styled.Td>
                  {order.first_bundle_or_fail ? "true" : "false"}
                </Styled.Td>
                <Styled.Td>
                  <button onClick={() => handleCancel(order.id)}>cancel</button>
                </Styled.Td>
              </tr>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.TableWrapper>
    </>
  );
};

const Orders = () => {
  const [tab, setTab] = useState(0);
  return (
    <>
      {tab === 0 && <LimitOrders tab={tab} setTab={setTab} />}
      {tab === 1 && <SnipeOrders tab={tab} setTab={setTab} />}
    </>
  );
};

export default Orders;

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { searchByQueryString } from "apis/external";
import currentPairState from "states/currentPair";
import { formatBigNumber } from "utils/format";
import { getQuoteTokenAddress } from "utils/address";
import { opacityState } from "../../states/opacity";
import ROUTES from "config/routes";

const Styled = {
  TokenPairTitle: styled.div`
    color: #fff;
    font-family: "Transducer Test";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  TokenInfo: styled.div`
    color: #8b8b8b;
    font-family: "Transducer Test";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  TokenInfoTitle: styled.div`
    color: #fff;
    font-family: "Transducer Test";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
};

const SearchToken = () => {
  const { setCurrentPair } = currentPairState();
  const [isShow, setIsShow] = useRecoilState(opacityState);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [pairs, setPairs] = useState<any>([]);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const isTradingAppPage = location.pathname === ROUTES.TRADING_APP.path;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setIsShow]);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchTerm) {
        const { data } = await searchByQueryString(debouncedSearchTerm);
        const { pairs } = data;
        setIsShow(true);
        if (pairs) {
          setPairs(pairs.filter((item: any) => item.chainId === "ethereum"));
        }
      }
    };

    fetchData();
  }, [debouncedSearchTerm, setIsShow]);

  const getPairs = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const showChart = (pair: any) => {
    setCurrentPair({
      pairAddress: pair.pairAddress,
      quoteToken: getQuoteTokenAddress(pair),
      chain: pair.chainId,
      labels: pair.labels,
    });
    setIsShow(false);
    setSearchTerm("");
  };

  if (!isTradingAppPage) return null;

  return (
    <div className="flex flex-1 border-orange rounded-lg border-2 h-10 items-center relative opacity-100">
      <div className="ml-2">
        <img src="search.png" alt="search" />
      </div>
      <div className="ml-2 font-transducer text-gray w-full">
        <input
          className="bg-secondary text-gray text-xs w-full focus:outline-none focus:ring-0 focus:border-transparent"
          placeholder="Input token name or address"
          type="text"
          value={searchTerm}
          onChange={getPairs}
        />
      </div>
      <div className="ml-auto bg-orange w-24 h-full flex items-center justify-center">
        <button className="text-white font-transducer text-sm text-center">
          Search
        </button>
      </div>
      {isShow && (
        <div
          ref={dropdownRef}
          className="absolute w-full top-8 bg-brown max-h-80 overflow-auto z-20"
        >
          {pairs.map((pair: any) => (
            <div
              key={pair.url}
              onClick={() => showChart(pair)}
              className="border-b border-t font-transducer flex items-center cursor-pointer hover:bg-selectedBrown hover:border-orange"
            >
              <div className="w-1/12 flex items-center justify-center">
                <img src="ethereum.png" alt="eth" />
              </div>
              <div className="w-1/2 text-white mt-2 mb-2">
                <Styled.TokenPairTitle>
                  {pair.baseToken.name +
                    " (" +
                    pair.baseToken.symbol +
                    "/" +
                    pair.quoteToken.symbol +
                    ") " +
                    pair?.labels?.[0]}
                </Styled.TokenPairTitle>
                <Styled.TokenInfo className="mt-2">
                  {"Pair: " + pair.pairAddress}
                </Styled.TokenInfo>
              </div>
              <div className="w-2/12">
                <div className="flex mt-2 mb-2">
                  <Styled.TokenInfoTitle className="mr-1">
                    Price:{" "}
                  </Styled.TokenInfoTitle>
                  <Styled.TokenInfo>
                    ${formatBigNumber(Number(pair.priceUsd))}
                  </Styled.TokenInfo>
                </div>
                <div className="flex mt-2 mb-2">
                  <Styled.TokenInfoTitle className="mr-1">
                    MC:{" "}
                  </Styled.TokenInfoTitle>
                  <Styled.TokenInfo>
                    ${formatBigNumber(Number(pair.fdv))}
                  </Styled.TokenInfo>
                </div>
              </div>
              <div className="w-3/12">
                <div className="flex mt-2 mb-2">
                  <Styled.TokenInfoTitle className="mr-1">
                    24H Vol:{" "}
                  </Styled.TokenInfoTitle>
                  <Styled.TokenInfo>
                    ${formatBigNumber(Number(pair.volume.h24))}
                  </Styled.TokenInfo>
                </div>
                <div className="flex mt-2 mb-2">
                  <Styled.TokenInfoTitle className="mr-1">
                    24H Change:{" "}
                  </Styled.TokenInfoTitle>
                  <Styled.TokenInfo
                    style={{
                      color: pair.priceChange.h24 > 0 ? "#09BE51" : "#CD1919",
                    }}
                  >
                    {formatBigNumber(Number(pair.priceChange.h24))}%
                  </Styled.TokenInfo>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchToken;

import styled from "styled-components";
import { useState } from "react";
import Orders from "./Orders";
import History from "./History";
import Position from "./Position";

const Styled = {
  Wrapper: styled.div`
    border-radius: 10px;
    background: #1e1e1e;
  `,
  Title: styled.h1`
    padding: 20px;
    border-radius: 9px 9px 0px 0px;
    background: #2f2f2f;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    font-family: "Transducer Test";
  `,

  DataArea: styled.div`
    padding: 20px;
  `,

  Actions: styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid linear-gradient(0deg, #33251e 0%, #33251e 100%), #f1651d;
    margin-bottom: 10px;
  `,
  Action: styled.div<{ active: boolean }>`
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border: 1px solid #54301e;
    text-transform: uppercase;
    background: linear-gradient(0deg, #33251e 0%, #33251e 100%), #f1651d;
    padding: 8px;
    font-family: "Transducer Test";
    font-size: 11px;
    ${({ active }: { active: boolean }) =>
      active &&
      `
      background: #F1651D;
      `};

    &:first-child {
      border-radius: 7.5px 0px 0px 7.5px;
    }

    &:last-child {
      border-radius: 0px 7.5px 7.5px 0px;
    }
  `,
};

const ManagePosition = () => {
  const [tab, setTab] = useState(0);

  return (
    <Styled.Wrapper>
      <Styled.Title>Positions</Styled.Title>

      <Styled.DataArea>
        <Styled.Actions>
          <Styled.Action active={tab === 0} onClick={() => setTab(0)}>
            All positions
          </Styled.Action>
          <Styled.Action active={tab === 1} onClick={() => setTab(1)}>
            Open Orders
          </Styled.Action>
          <Styled.Action active={tab === 2} onClick={() => setTab(2)}>
            History
          </Styled.Action>
        </Styled.Actions>
        {tab === 0 && <Position />}
        {tab === 1 && <Orders />}
        {tab === 2 && <History />}
      </Styled.DataArea>
    </Styled.Wrapper>
  );
};

export default ManagePosition;

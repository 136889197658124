/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import appInfoState from "states/appInfo";
import userInfoState from "states/user";
import authInfoState from "states/auth";

import ROUTES from "config/routes";
import useLogin from "hooks/useLogin";
import Login from "components/LoginModal";
import SelectWalletModal from "components/SelectWalletModal";
import { TelegramIcon, Logo } from "components/Icons";
import { HamburgerIcon } from "components/Icons";
import NavMenu from "./NavMenu";
import SearchToken from "./SearchToken";
import { connectors } from "components/SelectWalletModal/connectors";

const Header = () => {
  const { pathname } = useLocation();
  const showConnect = pathname === ROUTES.CLAIM_BOARD.path;

  const { appInfo } = appInfoState();
  const {
    userInfo: { alias, full_name, referral_id },
  } = userInfoState();
  const {
    authInfo: { isLoggedIn },
  } = authInfoState();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { handleLogout } = useLogin();
  const dropdownRef = useRef(null);
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
  }, []);

  useEffect(() => {
    const switchNetwork = async () => {
      if (!library || !library.provider) return;
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
    };

    if (active && chainId !== 1 && showConnect) {
      switchNetwork();
    }
  }, [active]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef, showDropdown]);

  const onClick = () => {
    if (isLoggedIn) {
      setShowDropdown((prev) => !prev);
    } else {
      setShowLoginModal(true);
    }
  };

  const referFriend = () => {
    const referLink = `https://t.me/looter_ai_bot?start=use_${referral_id}`;
    if ("clipboard" in navigator) {
      navigator.clipboard
        .writeText(referLink)
        .then(() => toast.success("Copied", { duration: 2000 }));
    } else {
      document.execCommand("copy", true, referLink);
    }
  };

  const onWeb3Connect = () => {
    if (!active) {
      setShowWalletConnectModal(true);
    } else {
      window.localStorage.setItem("provider", undefined);
      deactivate();
    }
  };

  return (
    <>
      <div className="bg-secondary w-full h-16 flex justify-between px-8 items-center">
        <div className="flex md:w-1/4 md:justify-evenly h-16 items-center gap-8">
          <div className="cursor-pointer" onClick={() => setMenuOpen(true)}>
            <HamburgerIcon />
          </div>
          <div className="h-full" style={{ marginTop: -10 }}>
            <Logo />
          </div>
          <button
            className="hidden md:block text-orange font-transducer text-sm bg-transOrange rounded-lg px-6 h-12"
            onClick={referFriend}
          >
            Refer a Friend
          </button>
        </div>
        <div className="flex-1 hidden md:block">
          <SearchToken />
        </div>

        {!showConnect ? (
          <div className="h-full flex items-center">
            <div className="hidden md:flex bg-background w-40 flex rounded-lg justify-center items-center text-white font-transducer mr-6">
              <div className="text-base mr-1">⧫</div>
              <div className="mr-2 text-xs">${appInfo.etherPrice}</div>
              <img src="looter-machine.png" alt="looter-machine" />
              <div className="ml-1 text-xs">{appInfo.gas}</div>
            </div>
            <div
              className={
                "relative w-24 h-8 px-2 text-xs font-transducer font-bold rounded-lg flex items-center justify-center cursor-pointer " +
                (!isLoggedIn ? "bg-blue text-white" : "bg-white text-blue")
              }
              ref={dropdownRef}
              onClick={onClick}
            >
              {isLoggedIn ? (
                <span className="truncate">{alias || full_name}</span>
              ) : (
                <div className="flex justify-center items-center">
                  <TelegramIcon />
                  <span className="ml-1">LOGIN</span>
                </div>
              )}
              <div
                className={
                  "absolute rounded-lg w-full h-full top-8 bg-blue text-white justify-center items-center " +
                  (isLoggedIn && showDropdown ? "flex" : "hidden")
                }
                onClick={handleLogout}
              >
                <TelegramIcon />
                <span className="ml-1">LOGOUT</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center">
            <div
              className="relative w-24 h-8 px-2 text-xs font-transducer font-bold rounded-lg flex items-center justify-center cursor-pointer bg-blue text-white hover:bg-primary"
              onClick={onWeb3Connect}
            >
              <span className="truncate">{active ? account : "Connect"}</span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-secondary h-16 flex items-center w-full px-8 md:hidden">
        <SearchToken />
      </div>
      <Login
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
      <SelectWalletModal
        showModal={showWalletConnectModal}
        setShowModal={setShowWalletConnectModal}
      />
      <NavMenu isMenuOpen={isMenuOpen} onMenuClose={() => setMenuOpen(false)} />
    </>
  );
};

export default Header;

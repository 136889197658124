import { postBuyOrder } from "apis/buySell";
import { ChangeEvent, useEffect, useState } from "react";
import { convertToWei } from "utils/number";
import walletsState from "states/wallets";
import styled from "styled-components";
import Wallets from "../Wallets";
import currentPairState from "states/currentPair";
import appInfoState from "states/appInfo";
import { getTokenBasicInfo, getTokenPriceETH } from "utils/token";
import { formatUnits, getAddress, parseEther } from "ethers";
import toast from "react-hot-toast";
import { useDebounce } from "@uidotdev/usehooks";

const Styled = {
  InputsWrapper: styled.div`
    margin: 0 12px;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    background: #2f2f2f;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  Label: styled.div`
    color: #8c8c8c;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  `,
  RelativeElement: styled.div`
    position: relative;
  `,
  InputField: styled.input`
    border-radius: 10px;
    background: #1e1e1e;
    text-align: right;
    padding-right: 10px;
    height: 45px;
    font-weight: 700;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `,
  TokenType: styled.span`
    position: absolute;
    color: white;
    font-weight: 700;
    left: 15px;
    top: 9px;
  `,
  Price: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    bottom: 0;
    right: 10px;
  `,
  Balance: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    left: 15px;
    bottom: 0;
  `,
  Optional: styled.p`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    margin-left: 25px;
    margin-top: 10px;
  `,
  OptionalItems: styled.div`
    display: flex;
    border-radius: 10px;
    background: #2f2f2f;
    justify-content: space-between;
  `,
  OptionalItem: styled.div`
    padding: 10 0px;
  `,
  OptionalLabel: styled.span`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    margin-left: 5px;
  `,
  GasWrapper: styled.div`
    display: flex;
    border-radius: 10px;
    background: #1e1e1e;
    padding: 10px;
  `,
  Gas: styled.div`
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    border-right: 1px solid #5a5a5a;
    position: absolute;
    top: 10px;
    padding-right: 10px;
    left: 10px;
  `,
  Button: styled.button`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: white;
    background-color: #09be51;
    cursor: pointer;
    width: 120px;
    font-family: "Transducer Test";
    font-weight: 700;
  `,
};

const Buy = () => {
  const { wallets } = walletsState();
  const { appInfo } = appInfoState();
  const [amount, setAmount] = useState("0.1");
  const debounceAmount = useDebounce(amount, 500);
  const [amountOut, setAmountOut] = useState("");
  const { currentPair } = currentPairState();
  const [slippage, setSlippage] = useState("0");
  const [extraGas, setExtraGas] = useState("3");
  const handleClick = async () => {
    const res = await postBuyOrder({
      wallets: wallets
        .filter((item) => item.isSelected)
        .map((item) => item.wallet_index)
        .join(","),
      amount: convertToWei(amount).toString(),
      token: currentPair.quoteToken,
      slippage,
      extraGas,
    });
    toast(res.data.message);
  };

  useEffect(() => {
    if (+debounceAmount <= 0) return;
    const setBalance = async () => {
      const tokenAddress = currentPair.quoteToken;
      const tokenPriceETH = await getTokenPriceETH(getAddress(tokenAddress));
      if (!tokenPriceETH) return;
      const tokenBasicInfo = await getTokenBasicInfo(tokenAddress);
      const amountOut =
        (BigInt(parseEther(debounceAmount)) *
          BigInt(10 ** tokenBasicInfo.decimals)) /
        BigInt(Math.round(tokenPriceETH * 10 ** 18));
      setAmountOut(
        (+formatUnits(amountOut, tokenBasicInfo.decimals)).toFixed(2)
      );
    };
    setBalance();
  }, [debounceAmount, currentPair]);

  return (
    <>
      <Wallets />
      <Styled.InputsWrapper>
        <div>
          <Styled.Label className="font-transducer text-xs">
            You Pay
          </Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType className="font-transducer text-sm">
              ETH
            </Styled.TokenType>
            <Styled.InputField
              className="w-full font-transducer text-sm"
              placeholder="0.0"
              value={amount}
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
            />
            <Styled.Price>${appInfo.etherPrice * +amount}</Styled.Price>
          </Styled.RelativeElement>
        </div>

        <div>
          <Styled.Label className="font-transducer text-xs">
            You Receive
          </Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType className="font-transducer text-sm">
              Token
            </Styled.TokenType>
            <Styled.InputField
              className="w-full font-transducer text-sm"
              placeholder="0.0"
              value={amountOut}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAmountOut(e.target.value)}
              disabled
            />
            <Styled.Balance>Balance: 0 Token</Styled.Balance>
            <Styled.Price>${appInfo.etherPrice * +amount}</Styled.Price>
          </Styled.RelativeElement>
        </div>
      </Styled.InputsWrapper>

      <Styled.Optional className="font-transducer text-sm">
        Optional
      </Styled.Optional>

      <div className="mt-1">
        <Styled.InputsWrapper>
          <Styled.OptionalItems>
            <Styled.OptionalItem>
              <Styled.OptionalLabel className="font-transducer text-xs">
                Extra Gas
              </Styled.OptionalLabel>
              <Styled.RelativeElement>
                <Styled.InputField
                  className="w-full font-transducer text-sm"
                  style={{ width: "100px" }}
                  placeholder="3.0"
                  value={extraGas}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setExtraGas(e.target.value)}
                />
                <Styled.Gas>Gwei</Styled.Gas>
              </Styled.RelativeElement>
            </Styled.OptionalItem>

            <Styled.OptionalItem>
              <Styled.OptionalLabel className="font-transducer text-xs">
                SLIPPAGE
              </Styled.OptionalLabel>
              <Styled.RelativeElement>
                <Styled.InputField
                  className="w-full font-transducer text-sm"
                  style={{ width: "100px" }}
                  placeholder="AUTO"
                  value={slippage}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSlippage(e.target.value)}
                />
                <Styled.Gas>%</Styled.Gas>
              </Styled.RelativeElement>
            </Styled.OptionalItem>
          </Styled.OptionalItems>
        </Styled.InputsWrapper>
      </div>

      <div className="mt-3 flex items-center justify-center">
        <Styled.Button onClick={handleClick}>BUY</Styled.Button>
      </div>
    </>
  );
};

export default Buy;

import { useState } from "react";
import { login } from "apis/auth";
import { setToken } from "service/storage";
import authInfoState from "states/auth";

const useLogin = () => {
  const { setAuthInfo } = authInfoState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean | null>(null);

  const handleLogin = async (code: string) => {
    try {
      setIsError(null);
      setIsLoading(true);
      const res = await login(code);
      if (res.status === 200) {
        const { accessToken } = res.data;
        setToken(accessToken);
        setAuthInfo({ isLoggedIn: true });
        setIsError(false);
      }
    } catch (err) {
      console.log(err);
      setAuthInfo({ isLoggedIn: false });
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setAuthInfo({ isLoggedIn: false });
    setToken("");
  };

  return { isLoading, isError, handleLogin, handleLogout };
};

export default useLogin;

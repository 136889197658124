import styled from "styled-components";
import { useState, useEffect } from "react";
import { formatEther } from "ethers";
import { getHistory } from "apis/user";
import appInfoState from "states/appInfo";
import { formatOrderType, formatPrice } from "utils/format";
import colors from "theme/colors";

const Styled = {
  TableWrapper: styled.div`
    max-height: 250px;
    overflow-y: auto;
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
  `,
  Th: styled.th`
    color: #8e8e8e;
    font-size: 12px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
  `,
  Td: styled.td<{ type?: string }>`
    color: ${({ type }: { type?: string }) =>
      type === "buy" ||
      type === "snipe" ||
      type === "limit buy" ||
      type === "anti rug"
        ? colors.greenAlpha
        : type === "sell" || type === "limit sell"
        ? colors.redAlpha
        : colors.white};
    font-size: 12px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid #303030;
    padding: 12px 20px 12px 0;
  `,
};
const getTokenPrice = (trade: any) => {
  return Math.abs(
    (trade.eth_amount * 10 ** trade.decimals) / 10 ** 18 / trade.token_amount
  );
};
const History = () => {
  const { appInfo } = appInfoState();
  const [history, setHistory] = useState([]);
  useEffect(() => {
    getHistory().then((res) => {
      setHistory(res.data);
    });
  }, []);

  return (
    <>
      <Styled.TableWrapper>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.Th>Date/Time</Styled.Th>
              <Styled.Th>Token</Styled.Th>
              <Styled.Th>Type</Styled.Th>
              <Styled.Th>Wallets</Styled.Th>
              <Styled.Th>Cost</Styled.Th>
              <Styled.Th>Price</Styled.Th>
              <Styled.Th></Styled.Th>
            </tr>
          </thead>
          <tbody>
            {history.map((history) => {
              const orderType = formatOrderType(
                history.order_type,
                history.type
              );
              return (
                <tr key={history.id}>
                  <Styled.Td>
                    {new Date(history.created_at).toLocaleString()}
                  </Styled.Td>
                  <Styled.Td>{history.symbol}</Styled.Td>
                  <Styled.Td type={orderType.toLowerCase()}>
                    {orderType}
                  </Styled.Td>
                  <Styled.Td>W {history.wallet.wallet_index}</Styled.Td>
                  <Styled.Td>
                    Ξ{Number(formatEther(history.eth_amount)).toFixed(5)}
                  </Styled.Td>
                  <Styled.Td>
                    ${formatPrice(getTokenPrice(history) * appInfo.etherPrice)}
                  </Styled.Td>
                  <Styled.Td>PNL Card</Styled.Td>
                </tr>
              );
            })}
          </tbody>
        </Styled.Table>
      </Styled.TableWrapper>
    </>
  );
};

export default History;

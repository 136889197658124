import styled from "styled-components";

const ProgressBar = styled.circle`
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

type ProgressBarProps = {
  text: string;
  percentage: number;
};

const CircularProgressBar = ({ text, percentage }: ProgressBarProps) => {
  return (
    <div className="relative w-40 h-40">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-darkOrange stroke-current"
          strokeWidth="7"
          cx="50"
          cy="50"
          r="40"
        />

        <circle
          className="text-secondary stroke-current"
          strokeWidth="7"
          cx="50"
          cy="50"
          r="33"
          fill="#2f2f2f"
        />

        <ProgressBar
          className="text-orange progress-ring__circle stroke-current"
          strokeWidth="7"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDashoffset={`calc(400 - 4 * 63 / 100 * ${percentage})`}
        />
        <text
          fontSize={12}
          textAnchor="middle"
          alignmentBaseline="middle"
          fill="white"
          className="font-transducer"
        >
          <tspan x="50" y="48">
            {text}
          </tspan>
          <tspan x="50" y="60">
            Points
          </tspan>
        </text>
      </svg>
    </div>
  );
};
export default CircularProgressBar;

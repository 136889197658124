import { getAddress } from "ethers";
import {
  WETH_ADDRESS,
  USDT_ADDRESS,
  DAI_ADDRESS,
  USDC_ADDRESS,
} from "config/constants";

export const isEqualAddress = (addr1: string, addr2: string): boolean => {
  return addr1.toLowerCase() === addr2.toLowerCase();
};

export const normalizeAddress = (addr: string): string => {
  return getAddress(addr);
};

export const getEllipsisAddress = (address: string): string => {
  // 0x1234...5678

  return address.slice(0, 6) + "..." + address.slice(-4);
};

export const getQuoteTokenAddress = (pair: any): string => {
  if (pair.quoteToken.address === WETH_ADDRESS) return pair.baseToken.address;
  if (pair.baseToken.address === WETH_ADDRESS) return pair.quoteToken.address;
  const addresses = [USDT_ADDRESS, DAI_ADDRESS, USDC_ADDRESS];
  if (addresses.includes(pair.quoteToken.address))
    return pair.baseToken.address;
  return pair.quoteToken.address;
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { CirclesWithBar } from "react-loader-spinner";
import toast from "react-hot-toast";

import ClaimCard from "components/ClaimCard";
import ClaimTable from "components/ClaimTable";
import SelectWalletModal from "components/SelectWalletModal";
import {
  claimReward,
  getClaimhistoryData,
  getRewardData,
  getWeeklyRevenueData,
} from "apis/rev";
import { getEtherPriceUSD } from "utils/token";
import { formatBalance, formatPrice } from "utils/format";
import { erc20 } from "contracts/erc20";
import { LOOTER_TOKEN_ADDRESS } from "config/constants";

const CLAIM_LIMIT = 10; // 10 USD

const ClaimBoard = () => {
  const { chainId, account, active } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const [weeklyData, setWeeklyData] = useState({
    apr: 0,
    weeklyTokenRevenue: "0",
    weeklyReferralRevenue: "0",
  });
  const [rewardData, setRewardData] = useState(0);
  const [claimHistory, setClaimHistory] = useState([]);
  const [ethPrice, setEthPrice] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0n);

  useEffect(() => {
    const getRevenueData = async () => {
      const [{ data: weekly }, price] = await Promise.all([
        getWeeklyRevenueData(),
        getEtherPriceUSD(),
      ]);

      setWeeklyData(weekly);
      setEthPrice(price);
    };
    getRevenueData();
  }, []);

  const getClaimData = async () => {
    try {
      setIsLoading(true);
      const [{ data: claim }, { data: history }, balance] = await Promise.all([
        getRewardData(account),
        getClaimhistoryData(account),
        erc20(LOOTER_TOKEN_ADDRESS).balanceOf(account),
      ]);

      setRewardData(claim.remaining);
      setClaimHistory(history);
      setTokenBalance(balance);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (active && chainId === 1 && account) {
      getClaimData();
    }
    if (!active) setTokenBalance(0n);
  }, [account, active, chainId]);

  const onClaim = async () => {
    if (!account || chainId !== 1) return;
    try {
      setIsClaiming(true);
      await claimReward(account);
      toast.success("Claimed successfully!", { position: "top-right" });
      getClaimData();
    } catch (e: any) {
      toast.error(e.response.data.errorMessage, { position: "top-right" });
    } finally {
      setIsClaiming(false);
    }
  };

  return (
    <div className="flex flex-col flex-grow w-full p-4 bg-background px-4 xl:px-80 font-montserrat">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CirclesWithBar color="#00BFFF" height={100} width={100} />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-4 w-full mb-4">
            <div className="lg:col-span-2 rounded-2xl px-2 sm:px-6 py-2 sm:py-4 text-white flex flex-col gap-2">
              <p className="font-semibold text-[21px]">Revenue Share</p>
              <div>
                <p className="font-semibold">
                  Token holders gain access to a share of the project's revenue.
                </p>
                <p className="font-semibold">
                  Minimum holding amount 10,000 $LOOTER.
                </p>
              </div>
            </div>
            <ClaimCard
              title="$LOOTER Balance"
              info={formatBalance(tokenBalance).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-4 w-full mb-4">
            <ClaimCard
              title="7-Day Revenue"
              info={"$" + formatPrice(+weeklyData.weeklyTokenRevenue)}
              subInfo={weeklyData.apr.toFixed(2) + "% APY"}
            />
            <ClaimCard
              title="7-Day Referral Revenue Payout"
              info={"$" + formatPrice(+weeklyData.weeklyReferralRevenue)}
            />
            {active ? (
              <ClaimCard
                title="Earned Rewards"
                info={
                  rewardData > 0 ? formatPrice(rewardData) + " ETH" : "0 ETH"
                }
                subInfo={
                  rewardData * ethPrice > 0 &&
                  "$" + formatPrice(rewardData * ethPrice)
                }
                buttonText={
                  rewardData * ethPrice < CLAIM_LIMIT
                    ? `Min $${CLAIM_LIMIT}`
                    : isClaiming
                    ? "Claiming"
                    : "Claim"
                }
                onClick={onClaim}
                buttonDisabled={
                  rewardData * ethPrice < CLAIM_LIMIT || isClaiming
                }
                containerStyle={
                  rewardData * ethPrice < CLAIM_LIMIT
                    ? ""
                    : "border border-primary"
                }
              />
            ) : (
              <ClaimCard
                title="Connect to see rewards"
                buttonText="Connect"
                onClick={() => setShowWalletConnectModal(true)}
                containerStyle="flex-col items-center"
              />
            )}
          </div>
          <ClaimTable cardTitle="Rewards Claim History" data={claimHistory} />
        </>
      )}
      <SelectWalletModal
        showModal={showWalletConnectModal}
        setShowModal={setShowWalletConnectModal}
      />
    </div>
  );
};

export default ClaimBoard;

import http from "./http";

export const getUserStatistics = () => {
  return http().get("/user/user-statistics");
};

export const getAllUserStatistics = () => {
  return http().get("/user-statistics/all");
};

export const getChallenges = () => {
  return http().get("/user-statistics/challenges");
};

import { ChangeEvent, useState } from "react";
import { postLimitBuy, postLimitSell } from "apis/limitOrder";
import walletsState from "states/wallets";
import toast from "react-hot-toast";
import Wallets from "../Wallets";
import currentPairState from "states/currentPair";
import styled from "styled-components";
import Dropdown from "components/common/Dropdown";
import colors from "theme/colors";

const Styled = {
  InputsWrapper: styled.div`
    margin: 0 12px;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    background: #2f2f2f;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  Label: styled.div`
    color: #8c8c8c;
    font-family: "Transducer Test";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  `,
  RelativeElement: styled.div`
    position: relative;
  `,
  InputField: styled.input`
    border-radius: 10px;
    background: #1e1e1e;
    text-align: right;
    padding-right: 10px;
    height: 45px;
    font-size: 17px;
    font-weight: 700;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `,
  TokenType: styled.span`
    position: absolute;
    font-size: 17px;
    color: white;
    font-weight: 700;
    left: 15px;
    top: 9px;
  `,
  Price: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    bottom: 0;
    right: 10px;
  `,
  Balance: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    left: 15px;
    bottom: 0;
  `,
  Optional: styled.p`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    margin-left: 25px;
    margin-top: 10px;
  `,
  OptionalItems: styled.div`
    display: flex;
    border-radius: 10px;
    background: #2f2f2f;
    justify-content: space-between;
  `,
  OptionalItem: styled.div`
    padding: 10 0px;
  `,
  OptionalLabel: styled.span`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    margin-left: 5px;
    text-transform: uppercase;
  `,
  Expired: styled.div`
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    border-right: 1px solid #5a5a5a;
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    text-transform: uppercase;
  `,
  Button: styled.button<{ type: string }>`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: white;
    background-color: ${({ type }: { type: string }) =>
      type === "buy" ? colors.green : colors.red};
    cursor: pointer;
    width: 120px;
    font-family: "Transducer Test";
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  `,
};

const priceUnits = [
  {
    value: "ETH",
    label: "ETH",
    displayLabel: "ETH",
  },
  {
    value: "USD",
    label: "USD",
    displayLabel: "USD",
  },
];

const amountUnits = [
  {
    value: "ETH",
    label: "ETH Amount",
    displayLabel: "ETH",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="18"
        viewBox="0 0 6 9"
        fill="none"
      >
        <path
          fill="#fff"
          d="M2.763 6.738 0 5.108 2.761 9l2.764-3.892-2.764 1.63h.002ZM2.803 0 .044 4.584l2.76 1.633 2.763-1.632L2.804 0Z"
        />
      </svg>
    ),
  },
  {
    value: "Token",
    label: "Token Amount",
    displayLabel: "Token",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 9 9"
        fill="none"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM5 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

type Props = {
  type: "buy" | "sell";
};

const LimitBuySell = ({ type }: Props) => {
  const { wallets } = walletsState();
  const { currentPair } = currentPairState();
  const [triggerPrice, setTriggerPrice] = useState("0");
  const [triggerPriceUnit, setTriggerPriceUnit] = useState("ETH");
  const [amount, setAmount] = useState("0.1");
  const [amountUnit, setAmountUnit] = useState("ETH");
  const [min, setMin] = useState("0");
  const [hour, setHour] = useState("0");
  const [day, setDay] = useState("0");

  const handleClick = async () => {
    const walletIndices = wallets
      .filter((item) => item.isSelected)
      .map((item) => item.wallet_index)
      .join(",");
    const expires = `${+min + +hour * 60 + +day * 60 * 24}`;
    const payload = {
      wallets: walletIndices,
      amount,
      amountUnit,
      token: currentPair.quoteToken,
      triggerPrice,
      triggerPriceUnit,
      expires,
    };

    if (type === "buy") {
      const res = await postLimitBuy(payload);
      toast(res.data.message);
    } else if (type === "sell") {
      const res = await postLimitSell(payload);
      toast(res.data.message);
    }
  };

  return (
    <>
      <Wallets />
      <Styled.InputsWrapper>
        <div>
          <Styled.Label>Trigger Price</Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType>
              <Dropdown
                items={priceUnits}
                value={triggerPriceUnit}
                onChange={(newUnit) => setTriggerPriceUnit(newUnit)}
              />
            </Styled.TokenType>
            <Styled.InputField
              className="w-full"
              placeholder="Amount"
              value={triggerPrice}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setTriggerPrice(e.target.value)
              }
            />
            <Styled.Balance>Balance: 0 USD</Styled.Balance>
            <Styled.Price>Est Cost: 0.0</Styled.Price>
          </Styled.RelativeElement>
        </div>

        <div>
          <Styled.Label>Amount</Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType>
              <Dropdown
                items={amountUnits}
                value={amountUnit}
                onChange={(newUnit) => setAmountUnit(newUnit)}
                wrapperClassName="w-56"
              />
            </Styled.TokenType>
            <Styled.InputField
              className="w-full"
              placeholder="Amount"
              value={amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAmount(e.target.value)
              }
            />
            <Styled.Balance>Balance: Ξ0</Styled.Balance>
            <Styled.Price>Est Tokens: 0.0</Styled.Price>
          </Styled.RelativeElement>
        </div>
      </Styled.InputsWrapper>

      <div className="mt-5">
        <Styled.InputsWrapper>
          <Styled.OptionalItems>
            <Styled.OptionalItem>
              <Styled.OptionalLabel>Expiration</Styled.OptionalLabel>
              <div className="flex items-center justify-center">
                <Styled.RelativeElement className="mr-2">
                  <Styled.InputField
                    style={{ width: "90px" }}
                    placeholder="0"
                    value={min}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setMin(e.target.value)
                    }
                  />
                  <Styled.Expired>Min</Styled.Expired>
                </Styled.RelativeElement>
                <Styled.RelativeElement className="mr-2">
                  <Styled.InputField
                    style={{ width: "90px" }}
                    placeholder="0"
                    value={hour}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setHour(e.target.value)
                    }
                  />
                  <Styled.Expired>Hour</Styled.Expired>
                </Styled.RelativeElement>
                <Styled.RelativeElement className="mr-2">
                  <Styled.InputField
                    style={{ width: "90px" }}
                    placeholder="0"
                    value={day}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setDay(e.target.value)
                    }
                  />
                  <Styled.Expired>Day</Styled.Expired>
                </Styled.RelativeElement>
              </div>
            </Styled.OptionalItem>
          </Styled.OptionalItems>
        </Styled.InputsWrapper>
      </div>

      <div className="mt-5 flex items-center justify-center">
        <Styled.Button type={type} onClick={handleClick}>
          {type === "buy" ? "Limit Buy" : "Limit Sell"}
        </Styled.Button>
      </div>
    </>
  );
};

export default LimitBuySell;

import { Pair, Token } from '@uniswap/sdk';
import { parseEther } from 'ethers';

export const getAmountOut = (
  amountIn: bigint,
  liquidityIn: bigint,
  liquidityOut: bigint,
): bigint => {
  const amountInWithFee = amountIn * 997n;
  const numerator = amountInWithFee * liquidityOut;
  const denominator = liquidityIn * 1000n + amountInWithFee;
  const amountOut = numerator / denominator;

  return amountOut;
};

export const getAmountIn = (
  amountOut: bigint,
  liquidityIn: bigint,
  liquidityOut: bigint,
): bigint => {
  const numerator = liquidityIn * amountOut * 1000n * parseEther('1');
  const denominator = (liquidityOut - amountOut) * 997n;
  return numerator / denominator / parseEther('1') + 1n;
};

export const getPairAddress = (token1: string, token2: string): string => {
  return Pair.getAddress(new Token(1, token1, 18), new Token(1, token2, 18));
};

import { ChangeEvent, useState } from "react";
import { USDT } from "service/constants";
import { postSnipe } from "apis/snipeOrder";
import walletsState from "states/wallets";
import Wallets from "../Wallets";
import toast from "react-hot-toast";
import styled from "styled-components";
import colors from "theme/colors";

const Styled = {
  InputsWrapper: styled.div`
    margin: 0 12px;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    background: #2f2f2f;
    display: flex;
    flex-direction: column;
  `,
  InputFieldArea: styled.div`
    display: flex;
    border-radius: 7px;
    background: ${colors.black};
    margin-bottom: 6px;
    align-items: center;
    height: 36px;

    /* &:last-child {
      width: 50%;
    } */
  `,
  Label: styled.span`
    border-right: 1px solid #2f2f2f;
    padding: 15px 4px;
    font-size: 10.5px;
    min-width: 111px;
    font-family: "Transducer Test";
    font-weight: 700;
    text-transform: uppercase;
  `,
  SwitchLabel: styled.span`
    padding: 15px 4px;
    font-size: 10.5px;
    font-family: "Transducer Test";
    font-weight: 700;
    text-transform: uppercase;
  `,
  Input: styled.input`
    padding-left: 5px;
    color: ${colors.white};
    font-family: "Transducer Test";
    font-size: 10.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: ${colors.black};
    max-width: 113px;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `,
  Switch: styled.div`
    margin-left: 10px;
    cursor: pointer;
  `,
  Button: styled.button`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: white;
    background-color: ${colors.green};
    cursor: pointer;
    width: 120px;
    font-family: "Transducer Test";
    font-weight: 700;
    text-transform: uppercase;
  `,
  AdvancedOptionsBtn: styled.button`
    border-radius: 7px;
    background: #434343;
    cursor: pointer;
    color: #fff;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 50%;
    margin: 20px 12px;
    padding: 10px;
    display: flex;
    align-items: center;
  `,
  AdvancedOptions: styled.div`
    border-radius: 7.453px;
    border: 1px solid #979797;
    background: #434343;
    color: #fff;
    padding: 10px;
    margin: 0 12px;
  `,
  AdvancedActions: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  OptionAction: styled.div`
    display: flex;
    align-items: center;
  `,
  OptionActionLabel: styled.span`
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  `,
  CloseOption: styled.button`
    cursor: pointer;
  `,
  OptionsWrapper: styled.div`
    padding: 15px 0 10px 10px;
  `,
  OptionField: styled.div`
    border-radius: 7px;
    background: ${colors.black};
    display: flex;
    align-items: center;
    position: relative;
  `,
  OptionLabel: styled.span`
    text-transform: uppercase;
    color: #fff;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-right: 1px solid #2f2f2f;
    padding: 15px 5px;
    min-width: 110px;
    font-family: "Transducer Test";
  `,
  OptionInput: styled.input`
    color: #494949;
    font-size: 12px;
    font-style: normal;
    background: ${colors.black};
    font-weight: 500;
    line-height: normal;
    padding-left: 5px;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `,
  AutoSellLabel: styled.p`
    margin: 0;
    padding: 10px 0 10px;
    color: #b6b6b6;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  `,
  Percent: styled.span`
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    position: absolute;
    right: 10px;
  `,
};

const Snipe = () => {
  const { wallets } = walletsState();
  const [amount, setAmount] = useState("0.1");
  const [token, setToken] = useState(USDT);
  const [maxMc, setMaxMc] = useState("500000");
  const [tip, setTip] = useState(0.05);
  const [isFail] = useState(false);
  const [autoSell] = useState(100);
  const [minLp] = useState("0");
  const [maxTax] = useState(99);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [firstBundle, setFirstBundle] = useState(false);
  const handleClick = async () => {
    const res = await postSnipe({
      wallets: wallets
        .filter((item) => item.isSelected)
        .map((item) => item.wallet_index)
        .join(","),
      amount,
      token,
      maxMc,
      tip,
      isFail,
      autoSell,
      minLp,
      maxTax,
    });
    toast(res.data.message);
  };
  return (
    <div className="flex justify-start flex-col w-full">
      <Wallets />
      {showAdvanced ? (
        <Styled.AdvancedOptions>
          <Styled.AdvancedActions>
            <Styled.OptionAction>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.33333 7.25925C9.33333 8.40474 8.40474 9.33333 7.25925 9.33333C6.11377 9.33333 5.18518 8.40474 5.18518 7.25925C5.18518 6.11377 6.11377 5.18518 7.25925 5.18518C8.40474 5.18518 9.33333 6.11377 9.33333 7.25925ZM8.44444 7.25925C8.44444 7.9138 7.9138 8.44444 7.25925 8.44444C6.60471 8.44444 6.07407 7.9138 6.07407 7.25925C6.07407 6.60471 6.60471 6.07407 7.25925 6.07407C7.9138 6.07407 8.44444 6.60471 8.44444 7.25925Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.8346 4.45264C10.7852 3.05047 9.24223 2.14218 7.93873 2.8243L7.76104 2.91728C7.44685 3.08168 7.07168 3.08168 6.75749 2.91728L6.5798 2.8243C5.2763 2.14218 3.73337 3.05047 3.68394 4.45264L3.67698 4.65051C3.66421 5.01311 3.46907 5.34038 3.1651 5.52708L2.99437 5.63193C1.76732 6.38563 1.76732 8.133 2.99437 8.8867L3.1651 8.99156C3.46907 9.17826 3.66421 9.50552 3.67698 9.86815L3.68394 10.066C3.73337 11.4682 5.2763 12.3765 6.5798 11.6943L6.75749 11.6014C7.07168 11.437 7.44685 11.437 7.76104 11.6014L7.93873 11.6943C9.24223 12.3765 10.7852 11.4682 10.8346 10.066L10.8416 9.86812C10.8543 9.50552 11.0495 9.17826 11.3534 8.99156L11.5242 8.8867C12.7512 8.133 12.7512 6.38563 11.5242 5.63193L11.3534 5.52708C11.0495 5.34038 10.8543 5.01311 10.8416 4.65051L10.8346 4.45264ZM8.3662 3.56791C9.05118 3.20946 9.91758 3.66934 9.9462 4.48114L9.95317 4.67901C9.97548 5.3125 10.3167 5.89898 10.8723 6.24024L11.043 6.34509C11.7264 6.76486 11.7264 7.75377 11.043 8.17354L10.8723 8.2784C10.3167 8.61965 9.97548 9.20613 9.95317 9.83966L9.9462 10.0375C9.91758 10.8493 9.05118 11.3092 8.3662 10.9507L8.18851 10.8577C7.60807 10.554 6.9105 10.554 6.33002 10.8577L6.15233 10.9507C5.46735 11.3092 4.60095 10.8493 4.57233 10.0375L4.56537 9.83966C4.54306 9.20613 4.20181 8.61965 3.64622 8.2784L3.47553 8.17354C2.79212 7.75377 2.79212 6.76486 3.47553 6.34509L3.64622 6.24024C4.20181 5.89898 4.54306 5.3125 4.56537 4.67898L4.57233 4.48114C4.60095 3.66934 5.46735 3.20946 6.15233 3.56791L6.33002 3.66089C6.91047 3.96466 7.60804 3.96466 8.18851 3.66089L8.3662 3.56791Z"
                  fill="white"
                />
              </svg>
              <Styled.OptionActionLabel className="ml-1">
                Advanced Options
              </Styled.OptionActionLabel>
            </Styled.OptionAction>
            <Styled.CloseOption onClick={() => setShowAdvanced(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
              >
                <line
                  x1="4.02941"
                  y1="8.97059"
                  x2="12.9706"
                  y2="8.97059"
                  stroke="white"
                  stroke-width="2.05882"
                  stroke-linecap="round"
                />
              </svg>
            </Styled.CloseOption>
          </Styled.AdvancedActions>

          <Styled.OptionsWrapper>
            <Styled.OptionField className="mb-2">
              <Styled.OptionLabel>Min Liquidity</Styled.OptionLabel>
              <Styled.OptionInput />
            </Styled.OptionField>
            <Styled.OptionField className="mb-2">
              <Styled.OptionLabel>Max Tax</Styled.OptionLabel>
              <Styled.OptionInput />
            </Styled.OptionField>

            <Styled.AutoSellLabel>Auto Sell</Styled.AutoSellLabel>
            <Styled.OptionField className="mb-2">
              <Styled.OptionLabel>Max Tax</Styled.OptionLabel>
              <Styled.OptionInput placeholder="0.0" />
              <Styled.Percent>%</Styled.Percent>
            </Styled.OptionField>
            <Styled.OptionField className="mb-2">
              <Styled.OptionLabel>Max Tax</Styled.OptionLabel>
              <Styled.OptionInput placeholder="0.0" />
              <Styled.Percent>%</Styled.Percent>
            </Styled.OptionField>
          </Styled.OptionsWrapper>
        </Styled.AdvancedOptions>
      ) : (
        <Styled.InputsWrapper>
          <Styled.InputFieldArea>
            <Styled.Label>Token Address</Styled.Label>
            <Styled.Input
              placeholder="0x..."
              value={token}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setToken(e.target.value)
              }
            />
          </Styled.InputFieldArea>

          <Styled.InputFieldArea>
            <Styled.Label>Amount</Styled.Label>
            <Styled.Input
              placeholder="0.0"
              value={amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAmount(e.target.value)
              }
            />
          </Styled.InputFieldArea>

          <Styled.InputFieldArea>
            <Styled.Label>Priority Tip</Styled.Label>
            <Styled.Input
              placeholder="0.0"
              value={tip}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setTip(+e.target.value)
              }
            />
          </Styled.InputFieldArea>

          <Styled.InputFieldArea>
            <Styled.Label>Max Market Cap</Styled.Label>
            <Styled.Input
              placeholder="0.0"
              value={maxMc}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMaxMc(e.target.value)
              }
            />
          </Styled.InputFieldArea>

          <Styled.InputFieldArea className="w-1/2 items-center justify-center">
            <Styled.SwitchLabel className="">First Bundle</Styled.SwitchLabel>
            <Styled.Switch onClick={() => setFirstBundle(!firstBundle)}>
              {firstBundle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                  fill="none"
                >
                  <path
                    d="M0 6.21557C0 2.7828 2.78281 0 6.21557 0H16.7844C20.2172 0 23 2.7828 23 6.21557C23 9.64833 20.2172 12.4311 16.7844 12.4311H6.21557C2.78281 12.4311 0 9.64833 0 6.21557Z"
                    fill="#838383"
                  />
                  <path
                    d="M0 6.21557C0 2.7828 2.78281 0 6.21557 0H16.7844C20.2172 0 23 2.7828 23 6.21557C23 9.64833 20.2172 12.4311 16.7844 12.4311H6.21557C2.78281 12.4311 0 9.64833 0 6.21557Z"
                    fill="#F1651D"
                  />
                  <path
                    d="M5.66981 8.9558L3.03105 6.37289L3.74442 5.59078L5.59175 7.40621L8.96272 3.37581L9.7452 4.07705L5.66981 8.9558Z"
                    fill="white"
                  />
                  <path
                    d="M23 6.21557C23 9.64833 20.3137 12.4311 17 12.4311C13.6863 12.4311 11 9.64833 11 6.21557C11 2.7828 13.6863 0 17 0C20.3137 0 23 2.7828 23 6.21557Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                  fill="none"
                >
                  <path
                    d="M5.66981 9.09345L3.03105 6.51054L3.74442 5.72843L5.59175 7.54386L8.96272 3.51346L9.7452 4.2147L5.66981 9.09345Z"
                    fill="white"
                  />
                  <path
                    d="M0 6.35326C0 2.9205 2.78281 0.137695 6.21557 0.137695H16.7844C20.2172 0.137695 23 2.9205 23 6.35326C23 9.78603 20.2172 12.5688 16.7844 12.5688H6.21557C2.78281 12.5688 0 9.78603 0 6.35326Z"
                    fill="#838383"
                  />
                  <path
                    d="M13.2589 9.1377C13.7539 8.5077 14.5729 7.4007 15.2299 6.5277C14.6269 5.6907 13.7989 4.54769 13.3309 3.8997H14.7259L15.4189 4.92569C15.6169 5.22269 15.8059 5.5197 15.9949 5.8077C16.1839 5.5287 16.3729 5.24069 16.5619 4.95269L17.2819 3.8997H18.5959C18.1099 4.54769 17.2639 5.6907 16.7059 6.4197C17.3179 7.2477 18.2179 8.47169 18.7399 9.1377H17.3449L16.5439 7.9857C16.3459 7.6977 16.1479 7.40069 15.9499 7.1217C15.7519 7.41869 15.5359 7.7337 15.3559 8.0037L14.5729 9.1377H13.2589Z"
                    fill="white"
                  />
                  <path
                    d="M12 6.21557C12 9.64833 9.31371 12.4311 6 12.4311C2.68629 12.4311 0 9.64833 0 6.21557C0 2.7828 2.68629 0 6 0C9.31371 0 12 2.7828 12 6.21557Z"
                    fill="white"
                  />
                </svg>
              )}
            </Styled.Switch>
          </Styled.InputFieldArea>
        </Styled.InputsWrapper>
      )}

      {!showAdvanced && (
        <Styled.AdvancedOptionsBtn onClick={() => setShowAdvanced(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.33333 7.25925C9.33333 8.40474 8.40474 9.33333 7.25925 9.33333C6.11377 9.33333 5.18518 8.40474 5.18518 7.25925C5.18518 6.11377 6.11377 5.18518 7.25925 5.18518C8.40474 5.18518 9.33333 6.11377 9.33333 7.25925ZM8.44444 7.25925C8.44444 7.9138 7.9138 8.44444 7.25925 8.44444C6.60471 8.44444 6.07407 7.9138 6.07407 7.25925C6.07407 6.60471 6.60471 6.07407 7.25925 6.07407C7.9138 6.07407 8.44444 6.60471 8.44444 7.25925Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8346 4.45264C10.7852 3.05047 9.24223 2.14218 7.93873 2.8243L7.76104 2.91728C7.44685 3.08168 7.07168 3.08168 6.75749 2.91728L6.5798 2.8243C5.2763 2.14218 3.73337 3.05047 3.68394 4.45264L3.67698 4.65051C3.66421 5.01311 3.46907 5.34038 3.1651 5.52708L2.99437 5.63193C1.76732 6.38563 1.76732 8.133 2.99437 8.8867L3.1651 8.99156C3.46907 9.17826 3.66421 9.50552 3.67698 9.86815L3.68394 10.066C3.73337 11.4682 5.2763 12.3765 6.5798 11.6943L6.75749 11.6014C7.07168 11.437 7.44685 11.437 7.76104 11.6014L7.93873 11.6943C9.24223 12.3765 10.7852 11.4682 10.8346 10.066L10.8416 9.86812C10.8543 9.50552 11.0495 9.17826 11.3534 8.99156L11.5242 8.8867C12.7512 8.133 12.7512 6.38563 11.5242 5.63193L11.3534 5.52708C11.0495 5.34038 10.8543 5.01311 10.8416 4.65051L10.8346 4.45264ZM8.3662 3.56791C9.05118 3.20946 9.91758 3.66934 9.9462 4.48114L9.95317 4.67901C9.97548 5.3125 10.3167 5.89898 10.8723 6.24024L11.043 6.34509C11.7264 6.76486 11.7264 7.75377 11.043 8.17354L10.8723 8.2784C10.3167 8.61965 9.97548 9.20613 9.95317 9.83966L9.9462 10.0375C9.91758 10.8493 9.05118 11.3092 8.3662 10.9507L8.18851 10.8577C7.60807 10.554 6.9105 10.554 6.33002 10.8577L6.15233 10.9507C5.46735 11.3092 4.60095 10.8493 4.57233 10.0375L4.56537 9.83966C4.54306 9.20613 4.20181 8.61965 3.64622 8.2784L3.47553 8.17354C2.79212 7.75377 2.79212 6.76486 3.47553 6.34509L3.64622 6.24024C4.20181 5.89898 4.54306 5.3125 4.56537 4.67898L4.57233 4.48114C4.60095 3.66934 5.46735 3.20946 6.15233 3.56791L6.33002 3.66089C6.91047 3.96466 7.60804 3.96466 8.18851 3.66089L8.3662 3.56791Z"
              fill="white"
            />
          </svg>
          <span className="ml-1">Advanced Options</span>
        </Styled.AdvancedOptionsBtn>
      )}

      <div className="mt-3 flex items-center justify-center">
        <Styled.Button onClick={handleClick}>Snipe</Styled.Button>
      </div>
      {/* <div>
        <span>Eth amount:</span>
        <input
          placeholder="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div>
        <span>Token address:</span>
        <input
          placeholder="token address"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </div>
      <div>
        <span>Max MC</span>
        <input
          placeholder="Max MC"
          value={maxMc}
          onChange={(e) => setMaxMc(e.target.value)}
        />
      </div>
      <div>
        <span>Priority tip</span>
        <input
          placeholder="Priority tip"
          value={tip}
          onChange={(e) => setTip(+e.target.value)}
        />
      </div>
      <div>
        <span>Is First bundle?</span>
        <input
          placeholder="First bundle"
          type="checkbox"
          checked={isFail}
          onChange={(e) => setIsFail(e.target.checked)}
        />
      </div>
      <div>
        <span>Auto sell</span>
        <input
          placeholder="Auto sell"
          value={autoSell}
          onChange={(e) => setAutoSell(+e.target.value)}
        />
      </div>
      <div>
        <span>Min Liq</span>
        <input
          placeholder="Min Liq"
          value={minLp}
          onChange={(e) => setMinLp(e.target.value)}
        />
      </div>
      <div>
        <span>Max Tax</span>
        <input
          placeholder="Max Tax"
          value={maxTax}
          onChange={(e) => setMaxTax(+e.target.value)}
        />
      </div>
      <button onClick={handleClick}>Snipe</button> */}
    </div>
  );
};

export default Snipe;

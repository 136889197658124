export const CloseIcon = () => (
  <svg width="22" height="19" fill="none">
    <path
      fill="#fff"
      d="M21.024 1.144A1.552 1.552 0 0 0 18.867.97L.831 15.964a1.48 1.48 0 0 0-.177 2.114c.546.632 1.512.71 2.156.174L20.847 3.258a1.48 1.48 0 0 0 .177-2.114Z"
    />
    <path
      fill="#fff"
      d="M.653 1.144A1.552 1.552 0 0 1 2.81.97l18.037 14.994a1.48 1.48 0 0 1 .177 2.114 1.552 1.552 0 0 1-2.157.174L.831 3.258a1.48 1.48 0 0 1-.178-2.114Z"
    />
  </svg>
);

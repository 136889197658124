import http from "./http";
import { getAddress, parseEther } from "ethers";

interface Snipe {
  wallets: string;
  amount: string;
  token: string;
  maxMc: string;
  tip: number;
  isFail: boolean;
  autoSell: number;
  minLp: string;
  maxTax: number;
}
export const postSnipe = async ({
  wallets,
  amount,
  token,
  maxMc,
  tip,
  isFail,
  autoSell,
  minLp,
  maxTax,
}: Snipe) => {
  const tokenAddress = getAddress(token);
  return http().post("/snipe/buy", {
    wallets,
    amount_in: parseEther(amount).toString(),
    token: tokenAddress,
    maxMc,
    tip: parseEther(tip.toString()).toString(),
    isFail,
    autoSell,
    minLp: parseEther(minLp).toString(),
    maxTax,
  });
};

export const getSnipeOrders = () => {
  return http().get("/snipe/orders");
};

export const cancelSnipeOrder = ({ id }: { id: number }) => {
  return http().post("/snipe/cancel", { id });
};

import { useWeb3React } from "@web3-react/core";
import { connectors } from "components/SelectWalletModal/connectors";

type ModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectWalletModal = ({ showModal, setShowModal }: ModalProps) => {
  const { activate } = useWeb3React();

  const setProvider = (type: string) => {
    window.localStorage.setItem("provider", type);
  };

  const closeModal = () => setShowModal(false);

  if (!showModal) return null;

  return (
    <div
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full text-black backdrop-blur-sm"
      onClick={closeModal}
    >
      <div
        className="flex flex-col bg-white rounded-lg p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <p>Select Wallet</p>
        <div className="flex flex-col gap-3 items-center p-4 rounded-b">
          <button
            className="border border-gray font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer w-full flex items-center justify-center gap-2 hover:bg-silver"
            onClick={() => {
              activate(connectors.coinbaseWallet);
              setProvider("coinbaseWallet");
              closeModal();
            }}
          >
            <img src="coinbase-wallet.png" alt="cbw" width={25} height={25} />
            Coinbase Wallet
          </button>
          <button
            className="border border-gray font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer w-full flex items-center justify-center gap-2 hover:bg-silver"
            onClick={() => {
              activate(connectors.walletConnect);
              setProvider("walletConnect");
              closeModal();
            }}
          >
            <img src="wallet-connect.png" alt="wc" width={25} height={25} />
            Wallet Connect
          </button>
          <button
            className="border border-gray font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer w-full flex items-center justify-center gap-2 hover:bg-silver"
            onClick={() => {
              activate(connectors.injected);
              setProvider("injected");
              closeModal();
            }}
          >
            <img src="metamask.png" alt="mm" width={25} height={25} />
            <span>Metamask</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectWalletModal;

import { atom } from "recoil";
import { useRecoilState } from "recoil";

export interface Wallet {
  address: string;
  wallet_index: number;
  isSelected: boolean;
  balance: bigint;
}

const walletsState = atom<Wallet[]>({
  key: "Wallets",
  default: [],
});
export default function State() {
  const [wallets, setWallets] = useRecoilState(walletsState);
  return { wallets, setWallets };
}

import http from "./http";
import {
  getTokenPriceETH,
  getTokenBasicInfo,
  getEtherPriceUSD,
} from "../utils/token";
import { getAddress, parseEther } from "ethers";
import { FEE_PERCENT_10000 } from "config/constants";

interface LimitBuy {
  wallets: string;
  amount: string;
  amountUnit: string;
  token: string;
  triggerPrice: string;
  triggerPriceUnit: string;
  expires: string;
}
interface LimitSell {
  wallets: string;
  amount: string;
  amountUnit: string;
  token: string;
  triggerPrice: string;
  triggerPriceUnit: string;
  expires: string;
}
export const postLimitBuy = async ({
  wallets,
  amount,
  amountUnit,
  token,
  triggerPrice,
  triggerPriceUnit,
  expires,
}: LimitBuy) => {
  const tokenAddress = getAddress(token);
  const tokenPriceETH = await getTokenPriceETH(tokenAddress);
  const etherPriceUSD = await getEtherPriceUSD();
  const targetPrice =
    triggerPriceUnit === "ETH"
      ? +triggerPrice
      : triggerPriceUnit === "USD"
      ? +triggerPrice / etherPriceUSD
      : (tokenPriceETH * (100 + +triggerPrice)) / 100;
  const ethAmount =
    amountUnit === "ETH" ? amount : `${+amount * tokenPriceETH}`;
  const tokenBasicInfo = await getTokenBasicInfo(tokenAddress);
  const amountOut =
    (BigInt(parseEther(ethAmount)) * BigInt(10 ** tokenBasicInfo.decimals)) /
    BigInt(Math.round(targetPrice * 10 ** 18));
  return http().post("/limit/buy", {
    wallets,
    amount_in: parseEther(ethAmount).toString(),
    token: tokenAddress,
    amount_out: BigInt(
      (amountOut * BigInt(10000 - FEE_PERCENT_10000)) / 10000n
    ).toString(),
    expires,
  });
};
export const postLimitSell = async ({
  wallets,
  amount,
  amountUnit,
  token,
  triggerPrice,
  triggerPriceUnit,
  expires,
}: LimitSell) => {
  const tokenAddress = getAddress(token);
  const tokenPriceETH = await getTokenPriceETH(tokenAddress);
  const etherPriceUSD = await getEtherPriceUSD();
  const targetPrice =
    triggerPriceUnit === "ETH"
      ? +triggerPrice
      : triggerPriceUnit === "USD"
      ? +triggerPrice / etherPriceUSD
      : (tokenPriceETH * (100 + +triggerPrice)) / 100;
  const ethAmount =
    amountUnit === "ETH" ? amount : `${+amount * tokenPriceETH}`;
  const tokenBasicInfo = await getTokenBasicInfo(tokenAddress);
  const amountOut = parseEther(ethAmount);
  const amountIn =
    (BigInt(parseEther(ethAmount)) * BigInt(10 ** tokenBasicInfo.decimals)) /
    BigInt(Math.round(targetPrice * 10 ** 18));
  return http().post("/limit/sell", {
    wallets,
    amount_in: amountIn.toString(),
    token: tokenAddress,
    amount_out: BigInt(
      (amountOut * BigInt(10000 - FEE_PERCENT_10000)) / 10000n
    ).toString(),
    expires,
  });
};

export const getLimitOrders = () => {
  return http().get("/limit/orders");
};

export const cancelLimitOrder = ({ id }: { id: number }) => {
  return http().post("/limit/cancel", { id });
};

import { formatUnits } from "ethers";

type TableProps = {
  cardTitle: string;
  data?: Array<{
    id: number;
    datetime: string;
    amount: string;
    status: string;
    link: string;
  }>;
};

const ClaimTable = ({ cardTitle, data }: TableProps) => {
  return (
    <div className="rounded-2xl text-center bg-secondary px-4 sm:px-12 flex flex-col h-full max-h-full overflow-y-auto">
      <div className="flex text-white items-center justify-center h-24">
        <h1 className="font-bold py-2 sm:py-4 bg-secondary text-white font-transducer m-3 text-lg sm:text-2xl">
          {cardTitle}
        </h1>
      </div>
      <div className="font-transducer relative flex-grow overflow-y-auto max-h-[500px]">
        <table className="w-full text-xs sm:text-sm">
          <thead className="text-md sticky top-0 z-10 bg-secondary text-base text-gray font-bold">
            <tr className="text-center">
              <th scope="col" className="md:px-6 py-3">
                No
              </th>
              <th scope="col" className="md:px-6 py-3">
                Date & Time
              </th>
              <th scope="col" className="md:px-6 py-3">
                Amount Claimed
              </th>
              <th scope="col" className="md:px-6 py-3">
                Status
              </th>
              <th scope="col" className="md:px-6 py-3">
                Transaction Link
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={5} className="p-20 font-bold text-xl">
                  Start claiming your rewards!
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr
                  key={index}
                  className="border-b-2 border-black border-gray-200 bg-transGray font-bold"
                >
                  <td className="px-4 sm:px-6 py-3">{index + 1}</td>
                  <td className="px-4 sm:px-6 py-3">
                    {new Date(item.datetime).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }) +
                      " " +
                      new Date(item.datetime).toLocaleString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                  </td>
                  <td className="px-4 sm:px-6 py-3">
                    {formatUnits(item.amount, 18)} ETH
                  </td>
                  <td
                    className={`px-4 capitalize sm:px-6 py-3 ${
                      item.status === "success"
                        ? "text-green-500"
                        : item.status === "failure"
                        ? "text-red-500"
                        : ""
                    }`}
                  >
                    {item.status}
                  </td>
                  <td className="px-4 sm:px-6 py-3 hover:underline">
                    <a
                      href={`https://etherscan.io/tx/${item.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.link.slice(0, 6) + "..." + item.link.slice(-6)}
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClaimTable;

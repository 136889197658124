type CardProps = {
  title: string;
  info?: string;
  subInfo?: string;
  buttonText?: string;
  onClick?: () => void;
  buttonDisabled?: boolean;
  containerStyle?: string;
};

const ClaimCard = ({
  title,
  info,
  subInfo,
  buttonText,
  onClick,
  buttonDisabled,
  containerStyle,
}: CardProps) => {
  return (
    <div
      className={
        "rounded-2xl bg-secondary px-2 sm:px-6 py-2 sm:py-4 flex flex-row justify-center font-montserrat text-white gap-2 " +
        containerStyle
      }
    >
      <div className="flex flex-col gap-2 justify-center items-center w-full">
        <h1 className="font-semibold text-[21px]">{title}</h1>
        {info && <h1 className="text-primary font-bold text-[24px]">{info}</h1>}
        {subInfo && <h1 className="font-semibold text-[19px]">{subInfo}</h1>}
      </div>
      {buttonText && (
        <div className="w-1/2 flex items-center justify-center">
          <button
            className={
              "font-bold py-2 px-4 rounded-lg " +
              (buttonDisabled ? "bg-gray" : "bg-blue hover:bg-primary")
            }
            onClick={onClick}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default ClaimCard;

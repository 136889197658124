import { atom, useRecoilState } from "recoil";

const currentPairState = atom<any>({
  key: "currentPair",
  default: {
    pairAddress: "0x11b815efB8f581194ae79006d24E0d814B7697F6",
    quoteToken: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    chain: "ethereum",
    labels: ["v3"]
  },
});
export default function State() {
  const [currentPair, setCurrentPair] = useRecoilState(currentPairState);
  return { currentPair, setCurrentPair };
}

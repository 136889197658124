import ProgressBar from "components/ProgressBar";
import Tooltip from "components/Tooltip";
import { UserStatistics } from "states/user";
import {
  BADGE_DESCRIPTION,
  badge_types,
  getBadge,
  getBadgeShadowColor,
  getHighestBadge,
  getNextBadgeStep,
} from "../../utils/badges";
import { formatPrice } from "utils/format";

const Profile = ({ userStatistics }: { userStatistics: UserStatistics }) => {
  const values: Record<badge_types, number> = {
    manual: userStatistics.total_manual_order,
    limit: userStatistics.total_limit_order,
    snipe: userStatistics.total_snipe_order,
    profit: +userStatistics.total_profit / 1000,
    loss: +userStatistics.total_loss / 1000,
    volume: +userStatistics.total_trade_volume_usd / 1000,
    invite: userStatistics.total_invite,
  };

  return (
    <div className="m-6 flex flex-col flex-1 font-transducer font-bold">
      <div className="flex mb-4 justify-between">
        <div className="flex">
          <div className="flex flex-col">
            <p className="text-gray uppercase">rank</p>
            <div className="text-6xl flex flex-1 items-center">
              {userStatistics.rank}
            </div>
          </div>
          <div className="flex flex-col mx-8">
            <p className="text-gray uppercase">points</p>
            <div className="text-3xl flex flex-1 items-center">
              {userStatistics.trading_point.toLocaleString()}
            </div>
          </div>
        </div>
        <div
          className="flex flex-col items-center w-28 h-28 border-orange border-2 justify-center rounded-full"
          style={{
            boxShadow: "0 0 10px 1px #EE5C34",
          }}
        >
          <p className="text-4xl">
            x{userStatistics.volumeMultiplier.toFixed(1)}
          </p>
          <p className="text-base uppercase">points</p>
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <p className="text-gray mb-2">BADGES</p>
        <div className="flex justify-between flex-wrap gap-y-2">
          {Object.keys(values).map((keyy) => {
            const key = keyy as badge_types;
            const badgeName = getBadge(key, values[key]);
            const isHighestBadge =
              badgeName === getHighestBadge(userStatistics).badgeName;
            return (
              <div
                key={key}
                className="flex flex-col items-center justify-center min-w-[90px]"
              >
                <Tooltip
                  title={BADGE_DESCRIPTION[key].name + " badge"}
                  subTitle={badgeName === "empty" ? "" : `(${badgeName})`}
                  description={BADGE_DESCRIPTION[key].description}
                  tooltipStyle="w-56"
                >
                  <img
                    src={`badges/${key + "_" + badgeName}.png`}
                    alt={key}
                    className="w-16 h-16 shadow-lg rounded-full"
                    style={{
                      boxShadow: isHighestBadge
                        ? getBadgeShadowColor(badgeName)
                        : "",
                    }}
                  />
                </Tooltip>
                <p className="text-white mt-2 text-xs uppercase">
                  {BADGE_DESCRIPTION[key].name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-1">
        <div className="text-gray mb-2">BADGE PROGRESSION</div>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          {Object.keys(values).map((keyy) => {
            const key = keyy as badge_types;
            return (
              <div key={key}>
                <div className="flex justify-between text-xs uppercase">
                  <div>{BADGE_DESCRIPTION[key].name}</div>
                  <div>
                    {["profit", "loss", "volume"].includes(key)
                      ? formatPrice(values[key])
                      : values[key]}
                    /{getNextBadgeStep(key, values[key])}
                  </div>
                </div>
                <ProgressBar
                  percentage={
                    (values[key] / getNextBadgeStep(key, values[key])) * 100
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Profile;

import http from "./http";

export const getWeeklyRevenueData = () => {
  return http().get("/rev/weekly-revenue");
};

export const getRewardData = (address: string) => {
  return http().get(`/rev/reward-data?address=${address}`);
};

export const getClaimhistoryData = (address: string) => {
  return http().get(`/rev/claim-history?address=${address}`);
};

export const claimReward = (address: string) => {
  return http().post(`/rev/claim-reward`, { address });
};

import { postSellOrder } from "apis/buySell";
import { ChangeEvent, useEffect, useState } from "react";
import walletsState from "states/wallets";
import styled from "styled-components";
import Wallets from "../Wallets";
import currentPairState from "states/currentPair";
import toast from "react-hot-toast";
import { useDebounce } from "@uidotdev/usehooks";
import appInfoState from "states/appInfo";
import { formatEther } from "ethers";
import { erc20 } from "contracts/erc20";
import { getTokenCustomInfo } from "service/simulation";
import { getRouteAmountOuts } from "utils/routing";

const Styled = {
  InputsWrapper: styled.div`
    margin: 0 12px;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    background: #2f2f2f;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,

  Label: styled.div`
    color: #8c8c8c;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  `,

  RelativeElement: styled.div`
    position: relative;
  `,

  InputField: styled.input`
    border-radius: 10px;
    background: #1e1e1e;
    text-align: right;
    padding-right: 10px;
    height: 45px;
    font-size: 17px;
    font-weight: 700;

    &:focus-visible {
      border: none;
      outline: none;
    }
  `,

  TokenType: styled.span`
    position: absolute;
    color: white;
    font-weight: 700;
    left: 15px;
    top: 9px;
  `,

  Price: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    bottom: 0;
    right: 10px;
  `,

  Balance: styled.span`
    position: absolute;
    color: #a0a0a0;
    font-size: 10px;
    left: 15px;
    bottom: 0;
  `,

  Optional: styled.p`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    margin-left: 25px;
    margin-top: 10px;
  `,

  OptionalItems: styled.div`
    display: flex;
    border-radius: 10px;
    background: #2f2f2f;
    justify-content: space-between;
  `,

  OptionalItem: styled.div`
    padding: 10 0px;
  `,

  OptionalLabel: styled.span`
    color: #5a5a5a;
    font-family: "Transducer Test";
    font-style: normal;
    font-weight: 700;
    margin-left: 5px;
  `,

  GasWrapper: styled.div`
    display: flex;
    border-radius: 10px;
    background: #1e1e1e;
    padding: 10px;
  `,

  Gas: styled.div`
    color: #fff;
    font-weight: 700;
    border-right: 1px solid #5a5a5a;
    position: absolute;
    top: 12px;
    padding-right: 10px;
    left: 10px;
  `,

  Slippage: styled.div`
    color: #fff;
    font-weight: 700;
    border-right: 1px solid #5a5a5a;
    position: absolute;
    top: 12px;
    padding-right: 10px;
    left: 10px;
  `,

  Button: styled.button`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: white;
    background-color: #cd1919;
    cursor: pointer;
    width: 120px;
    font-family: "Transducer Test";
    font-weight: 700;
  `,
};

const Sell = () => {
  const { wallets } = walletsState();
  const { appInfo } = appInfoState();
  const [percentage, setPercentage] = useState("30");
  const debouncePercent = useDebounce(percentage, 500);
  const [amountOut, setAmountOut] = useState("");
  const [slippage, setSlippage] = useState("0");
  const [extraGas, setExtraGas] = useState("3");
  const { currentPair } = currentPairState();
  const handleClick = async () => {
    const res = await postSellOrder({
      wallets: wallets
        .filter((item) => item.isSelected)
        .map((item) => item.wallet_index)
        .join(","),
      percentage: parseInt(debouncePercent),
      token: currentPair.quoteToken,
      slippage,
      extraGas,
    });
    toast(res.data.message);
  };
  useEffect(() => {
    if (+debouncePercent <= 0) return;
    const setBalance = async () => {
      const tokenAddress = currentPair.quoteToken;
      const tokenBalances = await Promise.all(
        wallets
          .filter((item) => item.isSelected)
          .map((wallet) => erc20(tokenAddress).balanceOf(wallet.address))
      );
      const currentBalance = tokenBalances.reduce(
        (partialSum, bal) => partialSum + bal,
        0n
      );
      const tokenCustomInfo = await getTokenCustomInfo(tokenAddress);
      const poolRoute = tokenCustomInfo.poolRoute;
      let [holdingValue = 0n] = poolRoute
        ? await getRouteAmountOuts(poolRoute, [currentBalance], "sell")
        : [0n];
      setAmountOut(formatEther(holdingValue));
    };
    setBalance();
  }, [debouncePercent, currentPair, wallets]);
  return (
    <>
      <Wallets />
      <Styled.InputsWrapper>
        <div>
          <Styled.Label className="font-transducer text-xs">
            You Sell
          </Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType className="font-transducer text-sm">
              %
            </Styled.TokenType>
            <Styled.InputField
              className="w-full font-transducer text-sm"
              placeholder="30"
              value={percentage}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPercentage(e.target.value)}
            />
            {/* <Styled.Balance>Balance: 0 Token</Styled.Balance> */}
            {/* <Styled.Price>$1.1232</Styled.Price> */}
          </Styled.RelativeElement>
        </div>

        <div>
          <Styled.Label className="font-transducer text-xs">
            You Receive
          </Styled.Label>
          <Styled.RelativeElement>
            <Styled.TokenType className="font-transducer text-sm">
              ETH
            </Styled.TokenType>
            <Styled.InputField
              className="w-full font-transducer text-sm"
              value={amountOut}
              disabled
            />
            <Styled.Price>${appInfo.etherPrice * +amountOut}</Styled.Price>
          </Styled.RelativeElement>
        </div>
      </Styled.InputsWrapper>

      <Styled.Optional className="font-transducer text-sm">
        Optional
      </Styled.Optional>

      <div className="mt-1">
        <Styled.InputsWrapper>
          <Styled.OptionalItems>
            <Styled.OptionalItem>
              <Styled.OptionalLabel className="font-transducer text-xs">
                Extra Gas
              </Styled.OptionalLabel>
              <Styled.RelativeElement>
                <Styled.InputField
                  style={{ width: "100px" }}
                  placeholder="Amount"
                  value={extraGas}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setExtraGas(e.target.value)}
                />
                <Styled.Gas className="font-transducer text-sm">
                  Gwei
                </Styled.Gas>
              </Styled.RelativeElement>
            </Styled.OptionalItem>

            <Styled.OptionalItem>
              <Styled.OptionalLabel className="font-transducer text-xs">
                SLIPPAGE
              </Styled.OptionalLabel>
              <Styled.RelativeElement>
                <Styled.InputField
                  className="w-full font-transducer text-sm"
                  style={{ width: "100px" }}
                  placeholder="AUTO"
                  value={slippage}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSlippage(e.target.value)}
                />
                <Styled.Slippage className="font-transducer text-sm">
                  %
                </Styled.Slippage>
              </Styled.RelativeElement>
            </Styled.OptionalItem>
          </Styled.OptionalItems>
        </Styled.InputsWrapper>
      </div>

      <div className="mt-3 flex items-center justify-center">
        <Styled.Button onClick={handleClick}>SELL</Styled.Button>
      </div>
    </>
  );
};

export default Sell;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { formatUnits } from "ethers";
import { getUserInfo } from "apis/user";
import userInfoState from "states/user";
import appInfoState from "states/appInfo";
import authInfoState from "states/auth";
import { provider } from "config/provider";
import { getEtherPriceUSD } from "utils/token";
import Header from "../Header";
import { opacityState } from "../../states/opacity";

type PropsType = {
  children?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<"div">;

const Layout = React.forwardRef(
  (props: PropsType, forwardedRef: React.Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;
    const isShow = useRecoilValue(opacityState);
    const { setAppInfo } = appInfoState();
    const { setUserInfo } = userInfoState();
    const {
      authInfo: { isLoggedIn },
    } = authInfoState();

    const [blocknum, setBlocknum] = useState(0);
    useEffect(() => {
      provider.on("block", (num: number) => {
        setBlocknum(num);
      });
    }, []);

    useEffect(() => {
      const getPriceInfo = async () => {
        const price = await getEtherPriceUSD();
        const feeData = await provider.getFeeData();
        setAppInfo({
          gas: +(+formatUnits(feeData.gasPrice, "gwei")).toFixed(2),
          etherPrice: +price.toFixed(2),
        });
      };

      getPriceInfo();
    }, [blocknum]);

    useEffect(() => {
      if (!isLoggedIn) return;
      getUserInfo()
        .then(({ data }) => {
          const {
            id,
            full_name,
            username,
            alias,
            referral_id,
            alpha_user,
            isAmbassador,
          } = data;
          setUserInfo({
            id,
            full_name,
            username,
            alias,
            referral_id,
            alpha_user,
            isAmbassador,
          });
        })
        .catch(() => {});
    }, [isLoggedIn]);

    return (
      <div
        ref={forwardedRef}
        className={`bg-dark-navy-blue text-white w-full h-screen min-h-screen flex flex-col items-center ${
          isShow ? "opacity-90" : ""
        }`}
        {...rest}
      >
        <Header />
        {children}
      </div>
    );
  }
);

export default Layout;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import userInfoState, { UserStatistics } from "states/user";
import authInfoState from "states/auth";
import {
  getAllUserStatistics,
  getChallenges,
  getUserStatistics,
} from "apis/user-statistics";

import DailyChallenges from "components/DailyChallenges";
import LeaderboardTable from "components/LeaderboardTable";
import Card from "components/Card";
import Profile from "components/Profile";
import { CirclesWithBar } from "react-loader-spinner";

export type Challenge = {
  id: number;
  category: "manual" | "snipe" | "limit" | "profit" | "social";
  name: string;
  description: string;
};

const Leaderboard = () => {
  const { userStatistics, setUserStatistics } = userInfoState();
  const {
    authInfo: { isLoggedIn },
  } = authInfoState();
  const blur = !isLoggedIn ? " blur-md" : "";

  const [userStatisticsList, setUserStatisticsList] = useState<
    UserStatistics[]
  >([]);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getChallenges().then(({ data }) => setChallenges(data)),
      getAllUserStatistics().then(({ data }) => setUserStatisticsList(data)),
      isLoggedIn
        ? getUserStatistics().then(({ data }) => setUserStatistics(data))
        : Promise.resolve(),
    ])
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoggedIn]);

  return (
    <div className="w-full flex flex-col-reverse md:flex-row flex-1 gap-8 p-4 bg-background justify-center">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CirclesWithBar color="#00BFFF" height={100} width={100} />
        </div>
      ) : (
        <>
          <Card
            containerStyle="w-full lg:w-2/5 bg-secondary overflow-hidden max-h-screen flex flex-col"
            cardTitle="Leaderboard"
            isSticky
          >
            <LeaderboardTable
              userStatisticsList={userStatisticsList}
              currentUser={userStatistics.user_id}
            />
          </Card>
          <div className="w-full lg:w-2/5 gap-2 flex flex-col relative">
            {!isLoggedIn && (
              <div className="absolute h-full w-full z-10 flex justify-center items-center text-lg">
                Login to check your progression
              </div>
            )}
            <Card
              cardTitle="Profile"
              containerStyle={
                "h-[80%] w-full bg-secondary flex flex-col" + blur
              }
            >
              <Profile userStatistics={userStatistics} />
            </Card>
            <Card
              cardTitle="Daily Challenges"
              containerStyle={"flex-1 w-full bg-secondary flex flex-col" + blur}
            >
              <DailyChallenges challenges={challenges} />
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default Leaderboard;

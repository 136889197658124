import { atom } from "recoil";
import { useRecoilState } from "recoil";

interface AppInfo {
  etherPrice: number;
  gas: number;
}

const appInfoState = atom<AppInfo>({
  key: "appInfo",
  default: {
    etherPrice: 1800,
    gas: 25,
  },
});

export default function State() {
  const [appInfo, setAppInfo] = useRecoilState(appInfoState);
  return { appInfo, setAppInfo };
}

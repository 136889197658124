import { parseEther, parseUnits } from "ethers";

// uniswap v3 constants
export const V3FACTORY_ADDRESS = "0x1F98431c8aD98523631AE4a59f267346ea31F984";
export const V3QUOTER_ADDRESS = "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";
export const V3FEES = [100, 500, 3000, 10000];

export const V2ROUTER_ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const WETH_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

export const USDT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const DAI_ADDRESS = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
export const USDC_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ETH_WS_URL = process.env.REACT_APP_ETH_WS_URL;
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

// Telegram
export const WALLET_LIMIT = 5;

export const TEST_TOKEN_ADDRESS = "0xf6Db96307A2dFA29d953A1323eBaB213D64e6c96";

export const TOKEN_UTILS_ADDRESS = "0x44619b7D86e90972B93C9Ff93B43b429E6387a75";
export const LOOTER_ADDRESS = "0xF268035F5F7Fa5BD43Eb8b84723D880Ec2748D81";
export const LOOTER_TOKEN_ADDRESS =
  "0x000000000a1c6659ac226dBB1C5BDC648DF72E9e";

export const SNIPE_DEFAULT_SETTING_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const GAS_FEES = {
  snipeOrder: {
    maxPriorityFeePerGas: parseUnits("0", "gwei"),
  },
  limitOrder: {
    maxPriorityFeePerGas: parseUnits("3", "gwei"),
  },
  panicSell: {
    maxPriorityFeePerGas: parseUnits("10", "gwei"),
  },
  sellTokens: {
    maxPriorityFeePerGas: parseUnits("3", "gwei"),
  },
  manualBuy: {
    maxPriorityFeePerGas: parseUnits("5", "gwei"),
  },
  blacklistBypassDelta: {
    maxPriorityFeePerGas: parseUnits("3", "gwei"),
  },
  antiRugDelta: {
    maxPriorityFeePerGas: parseUnits("3", "gwei"),
  },
  transferEth: {
    maxPriorityFeePerGas: parseUnits("3", "gwei"),
  },
};

export const DEADBLOCKS_LIMIT = 30;
export const DEADBLOCKS_TAX_LIMIT = 900; // 90%

export const USER_RATE_LIMIT = 5;
export const BLACKLIST_BYPASS_PERIOD = 1000 * 60 * 60 * 2; // 2 hours
export const BLACKLIST_BYPASS_WALLET_ETH_FILL = parseEther("0.02");

export const ANTI_RUG_PERIOD = 1000 * 60 * 60 * 2; // 24 hours

export const ANTI_RUG_PARAMS = {
  priceChange: 10, // if price becomes below 10% its rug
  sellTax: 90, // if sell tax becomes 90% its rug
  liquidityEth: parseEther("0.5"), // if liquidity becomes below 0.1 eth its rug
};

export const SELL_GAS_LIMIT = 1000000n;
export const BUY_GAS_LIMIT = 1000000n;

export const BETA_TESTING = true;

export const CERTAIN_TAX_PERCENT = 30;

export const ANTI_RUG_LIMIT = 2;

export const PNL_CARD_URL_PREFIX = "https://3-126-51-21.nip.io:8080/api/pnl?";

export const BUY_SELL_BLOCK_RETRIES = 4;

export const FEE_PERCENT_10000 = 100;

import { UserStatistics } from "states/user";

export type badge_types =
  | "manual"
  | "limit"
  | "snipe"
  | "profit"
  | "loss"
  | "volume"
  | "invite";

export const BADGE_DESCRIPTION: Record<
  badge_types,
  { name: string; description: string }
> = {
  manual: {
    name: "Trader",
    description:
      "An award for the most active traders. Those with the trader badge have bought every kind of token, good and bad. Do they know what they’re looking for? Not really, but they wake up and grind every day.",
  },
  limit: {
    name: "Scalper",
    description:
      "The scalper badge is for those who know every chart level. Traders with this badge, technical analysis is not the only tool in their arsenal. They buy the bottom and sell the top, every time. Keep watching at those 1 second charts, you’ll make it one day!",
  },
  snipe: {
    name: "Marksman",
    description:
      "The marksman badge is for the most battle hardened trader. The awarded sniper has been included the first bundle, on the front lines, more times than we can count. They always tip to the max and send all of their ETH to builders, but maximise their profits with Looter.",
  },
  profit: {
    name: "GOAT",
    description:
      "It’s an honor to be in their presence, we call you ser, this medal is for the most prestigious of trader. Calculated risk is their forte and they never buy pump and dumps. They use every tool to their advantage and to the fullest, always take profit at every leg up!",
  },
  loss: {
    name: "REKT",
    description:
      "An award for the worst trader in your circle. Rekt traders buy the top and sell the bottom, just to watch the price moon 10x from their sell. They are the top signal, but will never stop buying calls too late.",
  },
  volume: {
    name: "Whale",
    description:
      "A trophy for the an upcoming whale. They buy every signal, who cares if the deployer holds 90% of the supply. Tokenomics don’t matter, vibes do, and they bring them.",
  },
  invite: {
    name: "Matchmaker",
    description:
      "An award blessed to those who are basically a KOL, if not already. Looter is in their blood and bones, so they share their referral code anywhere and everywhere possible. The community thanks them for sharing Looter and they’ve been here from the very beginning.",
  },
};

const BADGE_LEVEL: Record<badge_types, [number, number, number, number]> = {
  manual: [1, 50, 200, 1000],
  limit: [1, 5, 20, 100],
  snipe: [1, 5, 20, 100],
  profit: [200, 5000, 20000, 200000],
  loss: [200, 10000, 50000, 500000],
  volume: [200, 2000, 400000, 2000000],
  invite: [1, 5, 50, 250],
};

export const getBadge = (badgeName: badge_types, amount: number): string => {
  const steps = BADGE_LEVEL[badgeName];
  if (amount >= steps[3]) return "ether";
  if (amount >= steps[2]) return "gold";
  if (amount >= steps[1]) return "silver";
  if (amount >= steps[0]) return "bronze";
  return "empty";
};

export const getNextBadgeStep = (
  badgeName: badge_types,
  amount: number
): number => {
  const steps = BADGE_LEVEL[badgeName];
  if (amount >= steps[3]) return -1;
  if (amount >= steps[2]) return steps[3];
  if (amount >= steps[1]) return steps[2];
  if (amount >= steps[0]) return steps[1];
  return steps[0];
};

export const getHighestBadge = (
  data: Pick<
    UserStatistics,
    | "total_manual_order"
    | "total_limit_order"
    | "total_snipe_order"
    | "total_profit"
    | "total_loss"
    | "total_trade_volume_usd"
    | "total_invite"
  >
): {
  badgeName: badge_types;
  badveLevel: string;
} => {
  const {
    total_manual_order,
    total_limit_order,
    total_snipe_order,
    total_profit,
    total_loss,
    total_trade_volume_usd,
    total_invite,
  } = data;

  const values: Record<badge_types, number> = {
    manual: total_manual_order,
    limit: total_limit_order,
    snipe: total_snipe_order,
    profit: +total_profit / 1000,
    loss: +total_loss / 1000,
    volume: +total_trade_volume_usd / 1000,
    invite: total_invite,
  };

  let highestBadge: badge_types = "manual";
  let value;
  let max = 0;

  for (let i = 0; i < Object.keys(values).length; i++) {
    const key: badge_types = Object.keys(values)[i] as badge_types;
    if (values[key] / BADGE_LEVEL[key][3] > max) {
      max = values[key] / BADGE_LEVEL[key][3];
      value = values[key];
      highestBadge = key;
    }
  }

  return {
    badgeName: highestBadge,
    badveLevel: getBadge(highestBadge, value),
  };
};

export const getBadgeShadowColor = (badge: string) => {
  if (badge === "ether") return "0 0 10px 7px rgba(211, 180, 255, 0.5)";
  if (badge === "gold") return "0 0 10px 7px rgba(255, 216, 165, 0.5)";
  if (badge === "silver") return "0 0 10px 7px rgba(226, 226, 226, 0.5)";
  if (badge === "bronze") return "0 0 10px 7px rgba(244, 186, 174, 0.5)";
  if (badge === "empty") return "";
};

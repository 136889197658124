import { TransactionResponse } from "ethers";
import { V3FACTORY_ADDRESS, V3FEES, WETH_ADDRESS } from "../config/constants";
import { router } from "../contracts/router";
import { v3Quoter } from "../contracts/v3-quoter";
import { simulateTxs } from "../service/simulation";
import { erc20 } from "../contracts/erc20";
import { getPairAddress } from "./uniswap";
import { getEtherPriceUSD } from "./token";
import { computePoolAddress } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";

type MediumTokenAddresses = {
  [key: string]: string;
};

const mediumTokenAddresses: MediumTokenAddresses = {
  DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
};

export type PoolRoute = {
  type: "v3" | "v2" | string;
  fee?: number;
  mediumTokenAddress?: "DAI" | "USDC" | "USDT" | string;
  isSell: boolean;
  amountIn: bigint;
  amountOut: bigint;
  liquidityValueETH: bigint; // liquidity value in eth
  liquidityToken: bigint; // liquidity token amount
  tokenAddress: string;
  poolAddress: string;
  signature: string; // identification of pool
};

export const formatPoolRoute = (poolRoute: PoolRoute) => {
  if (!poolRoute) return "None";
  if (poolRoute.type === "v3") {
    return `V3`;
  } else if (poolRoute.type === "v2") {
    if (poolRoute.mediumTokenAddress) {
      const mediumToken = Object.entries(mediumTokenAddresses).find(
        ([, address]) => address === poolRoute.mediumTokenAddress
      )[0];

      return `V2, ${mediumToken}`;
    } else {
      return "V2";
    }
  } else {
    return "unknown";
  }
};

type SimulationContext = {
  afterTxs?: TransactionResponse[];
  blockNumber?: number;
};

// for regular buy/sell we need to know best pool for given trade size
export const getPoolRouteForTrade = async (
  tokenAddress: string,
  amountIn: bigint,
  isSell = false,
  { afterTxs = [], blockNumber }: SimulationContext = {}
): Promise<PoolRoute | null> => {
  const quoterContract = v3Quoter();
  const tokenIn = isSell ? tokenAddress : WETH_ADDRESS;
  const tokenOut = isSell ? WETH_ADDRESS : tokenAddress;

  const mediumTokens = Object.keys(mediumTokenAddresses);

  const simulatingTxs = await Promise.all([
    ...V3FEES.map((fee) =>
      quoterContract.quoteExactInputSingle.populateTransaction(
        tokenIn,
        tokenOut,
        fee,
        amountIn,
        0
      )
    ),
    ...mediumTokens.map((mediumToken) =>
      router.getAmountsOut.populateTransaction(amountIn, [
        tokenIn,
        mediumTokenAddresses[mediumToken],
        tokenOut,
      ])
    ),
    router.getAmountsOut.populateTransaction(amountIn, [tokenIn, tokenOut]),
  ]);

  const result = await simulateTxs(
    [...afterTxs, ...simulatingTxs],
    blockNumber
  );
  const v3Results = result.slice(
    afterTxs.length,
    afterTxs.length + V3FEES.length
  );
  const v2WithMediumResults = result.slice(
    afterTxs.length + V3FEES.length,
    afterTxs.length + V3FEES.length + mediumTokens.length
  );
  const v2DirectResults = result.slice(
    afterTxs.length + V3FEES.length + mediumTokens.length
  );

  const poolRoutes: Omit<
    PoolRoute,
    | "liquidityValueETH"
    | "liquidityToken"
    | "poolAddress"
    | "tokenAddress"
    | "signature"
  >[] = [
    ...v3Results.map((result, i) => ({
      type: "v3",
      amountIn,
      isSell,
      fee: V3FEES[i],
      amountOut: result.output
        ? quoterContract.interface.decodeFunctionResult(
            "quoteExactInputSingle",
            result.output
          )[0]
        : 0n,
    })),
    ...v2WithMediumResults.map((result, i) => ({
      type: "v2",
      amountIn,
      isSell,
      mediumToken: mediumTokenAddresses[mediumTokens[i]],
      amountOut: result.output
        ? router.interface.decodeFunctionResult(
            "getAmountsOut",
            result.output
          )[0][2]
        : 0n,
    })),
    {
      type: "v2",
      amountIn,
      isSell,
      amountOut: v2DirectResults[0].output
        ? router.interface.decodeFunctionResult(
            "getAmountsOut",
            v2DirectResults[0].output
          )[0][1]
        : 0n,
    },
  ];
  poolRoutes.sort((a, b) => Number(b.amountOut - a.amountOut));

  const poolRoute = poolRoutes[0];

  let liquidityValueETH = 0n;
  let liquidityToken = 0n;
  let poolAddress;

  if (!poolRoute.amountOut) return null;

  if (poolRoute.type === "v2") {
    if (poolRoute.mediumTokenAddress) {
      poolAddress = getPairAddress(tokenAddress, poolRoute.mediumTokenAddress);
      const [
        liqUSDBalance,
        mediumTokenDecimals,
        etherPriceUSD,
        liquidityToken1,
      ] = await Promise.all([
        erc20(poolRoute.mediumTokenAddress).balanceOf(poolAddress),
        erc20(poolRoute.mediumTokenAddress).decimals(),
        getEtherPriceUSD(),
        erc20(tokenAddress).balanceOf(poolAddress),
      ]);
      liquidityToken = liquidityToken1;
      liquidityValueETH =
        (liqUSDBalance * 10n ** 18n) /
        10n ** mediumTokenDecimals /
        BigInt(Math.floor(etherPriceUSD * 1000)) /
        1000n;
    } else {
      poolAddress = getPairAddress(tokenAddress, WETH_ADDRESS);
      [liquidityValueETH, liquidityToken] = await Promise.all([
        erc20(WETH_ADDRESS).balanceOf(poolAddress),
        erc20(tokenAddress).balanceOf(poolAddress),
      ]);
    }
  } else {
    // v3
    poolAddress = computePoolAddress({
      factoryAddress: V3FACTORY_ADDRESS,
      tokenA: new Token(1, tokenIn, 1),
      tokenB: new Token(1, tokenOut, 1),
      fee: poolRoute.fee,
    });

    [liquidityValueETH, liquidityToken] = await Promise.all([
      erc20(WETH_ADDRESS).balanceOf(poolAddress),
      erc20(tokenAddress).balanceOf(poolAddress),
    ]);
  }

  return {
    ...poolRoute,
    liquidityValueETH,
    liquidityToken,
    poolAddress,
    tokenAddress,
    signature: `${poolRoute.type}-${poolRoute.fee}-${poolRoute.mediumTokenAddress}`,
  };
};

export const getRouteAmountOuts = async (
  poolRoute: PoolRoute,
  ethAmountsIn: bigint[],
  buyOrSell: "buy" | "sell" = "buy",
  afterTxs: TransactionResponse[] = []
): Promise<bigint[]> => {
  const tokenIn = buyOrSell === "buy" ? WETH_ADDRESS : poolRoute.tokenAddress;
  const tokenOut = buyOrSell === "buy" ? poolRoute.tokenAddress : WETH_ADDRESS;

  if (poolRoute.type === "v3") {
    const quoterContract = v3Quoter();
    const getAmountsTxs = await Promise.all(
      ethAmountsIn.map((ethAmountIn) =>
        quoterContract.quoteExactInputSingle.populateTransaction(
          tokenIn,
          tokenOut,
          poolRoute.fee,
          ethAmountIn,
          0
        )
      )
    );

    const result = await simulateTxs([...afterTxs, ...getAmountsTxs]);
    return result
      .slice(afterTxs.length)
      .map((result) =>
        result.output
          ? quoterContract.interface.decodeFunctionResult(
              "quoteExactInputSingle",
              result.output
            )[0]
          : 0n
      );
  } else {
    // v2
    if (poolRoute.mediumTokenAddress) {
      const getAmountsTxs = await Promise.all(
        ethAmountsIn.map((ethAmountIn) =>
          router.getAmountsOut.populateTransaction(ethAmountIn, [
            tokenIn,
            mediumTokenAddresses[poolRoute.mediumTokenAddress],
            tokenOut,
          ])
        )
      );

      const result = await simulateTxs([...afterTxs, ...getAmountsTxs]);
      return result
        .slice(afterTxs.length)
        .map((result) =>
          result.output
            ? router.interface.decodeFunctionResult(
                "getAmountsOut",
                result.output
              )[0][2]
            : 0n
        );
    } else {
      const getAmountsTxs = await Promise.all(
        ethAmountsIn.map((ethAmountIn) =>
          router.getAmountsOut.populateTransaction(ethAmountIn, [
            tokenIn,
            tokenOut,
          ])
        )
      );

      const result = await simulateTxs([...afterTxs, ...getAmountsTxs]);
      return result
        .slice(afterTxs.length)
        .map((result) =>
          result.output
            ? router.interface.decodeFunctionResult(
                "getAmountsOut",
                result.output
              )[0][1]
            : 0n
        );
    }
  }
};

import ReferralCard from "components/ReferralCard";
import ReferralTable from "components/ReferralTable";
import { useEffect, useState } from "react";
import userInfoState from "states/user";
import { getAmbassadorData, getAmbassadorList } from "apis/referralDashboard";
import { useNavigate } from "react-router-dom";
import { CirclesWithBar } from "react-loader-spinner";
import ROUTES from "config/routes";

interface AmbassadorData {
  userId: bigint;
  rev_bonus: number;
  next_rev_bonus: number;
  daily_volume: number;
  daily_earning: number;
  weekly_growth: number;
  total_volume: number;
  total_earning: number;
  claimable_earning: number;
}

const AffiliateDashboard = () => {
  const navigate = useNavigate();

  const {
    userInfo: { alias, isAmbassador, id, full_name },
  } = userInfoState();

  const [tableData, setTableData] = useState([]);
  const [myData, setMyData] = useState<AmbassadorData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const apiListData = (await getAmbassadorList()).data;
        if (apiListData) setTableData(apiListData);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    // Check if the user is not an ambassador
    if (!isAmbassador) navigate(ROUTES.LEADERBOARD.path);
  }, [isAmbassador, navigate]);

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const fetchedData = (await getAmbassadorData(id)).data;
        if (fetchedData) {
          setMyData(fetchedData);
        }
      };
      getData();
    }
  }, [id]);

  const myDataArray = [
    { title: "Name", value: alias || full_name || "N/A" },
    { title: "Rev Bonus", value: `${myData?.rev_bonus || 0}%` },
    { title: "Next Bonus", value: `${myData?.next_rev_bonus || 0}%` },
    {
      title: "24H Volume",
      value: `$${myData?.daily_volume?.toFixed(2) || "0.00"}`,
    },
    {
      title: "24H Earnings",
      value: `$${myData?.daily_earning?.toFixed(2) || "0.00"}`,
    },
    {
      title: "7-Day Growth",
      value: `${myData?.weekly_growth?.toFixed(4) || "0.00"}%`,
    },
    {
      title: "Total Ref Volume",
      value: `$${myData?.total_volume?.toFixed(2) || "0.00"}`,
    },
    {
      title: "Total Ref Earnings",
      value: `$${myData?.total_earning?.toFixed(2) || "0.00"}`,
    },
    {
      title: "Claimable Earnings",
      value: `$${myData?.claimable_earning?.toFixed(2) || "0.00"}`,
    },
  ];

  return (
    <div className="w-full flex-col-reverse xl:flex-row gap-8 p-4 bg-background justify-center px-4 xl:px-20">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CirclesWithBar color="#00BFFF" height={100} width={100} />
        </div>
      ) : (
        <>
          <hr className="my-2 xl:my-4 border-t border-brown" />
          <main className="flex flex-col xl:flex-row gap-4 xl:gap-8">
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex-[2]">
                <ReferralTable
                  containerStyle="flex flex-col h-full overflow-auto"
                  cardTitle="Leaderboard"
                  data={tableData}
                />
              </div>
              <div className="flex-1 flex flex-col gap-4">
                <div className="grid grid-cols-3 gap-2 md:gap-4">
                  {myDataArray.slice(0, 6).map((data, index) => (
                    <ReferralCard
                      key={index}
                      cardTitle={index === 0 ? "Name" : data.title}
                      containerStyle="w-full flex flex-col"
                      buttonText={data.value}
                    />
                  ))}
                </div>
                <div className="grid grid-cols-2 gap-2 md:gap-4">
                  {myDataArray.slice(6, 8).map((data, index) => (
                    <ReferralCard
                      key={index + 6}
                      cardTitle={data.title}
                      containerStyle="w-full flex flex-col"
                      buttonText={data.value}
                    />
                  ))}
                </div>
                <div className="grid grid-cols-1 gap-2 md:gap-4">
                  {myDataArray.slice(8).map((data, index) => (
                    <ReferralCard
                      key={index + 8}
                      cardTitle={data.title}
                      containerStyle="w-full flex flex-col"
                      buttonText={data.value}
                      buttonColor="bg-primary"
                      hoverLink="https://docs.looter.ai/rewards-and-ecosystem/referral-program"
                    />
                  ))}
                </div>
              </div>
            </div>
          </main>
          <hr className="my-2 xl:my-4 border-t border-brown" />
          <footer className="bg-gray-700 text-white p-2 xl:p-4 font-transducer">
            <a href="https://twitter.com/looter_ai" className="mx-2">
              Twitter
            </a>
            <a href="https://t.me/looter_ai_bot" className="mx-2">
              Telegram
            </a>
            <a href="https://docs.looter.ai/" className="mx-2">
              Docs
            </a>
          </footer>
        </>
      )}
    </div>
  );
};

export default AffiliateDashboard;

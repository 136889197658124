export const TelegramIcon = () => (
  <svg width="15" height="12" fill="none">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M.967 5.046c3.79-1.646 6.313-2.74 7.58-3.27C12.153.27 12.911.01 13.398 0c.109 0 .347.022.51.152.13.108.162.249.183.357.022.108.044.336.022.509-.195 2.057-1.04 7.05-1.473 9.345-.184.975-.541 1.3-.888 1.332-.758.065-1.332-.498-2.057-.975-1.148-.747-1.787-1.212-2.902-1.949-1.289-.844-.455-1.31.281-2.068.195-.195 3.52-3.227 3.585-3.498.01-.032.01-.162-.065-.227-.076-.065-.184-.043-.271-.022-.12.022-1.938 1.235-5.48 3.628-.52.357-.985.53-1.407.52-.466-.011-1.354-.26-2.025-.477-.812-.26-1.462-.4-1.408-.855.033-.238.358-.477.964-.726Z"
      clipRule="evenodd"
    />
  </svg>
);

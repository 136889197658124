import Drawer from "react-modern-drawer";
import { useNavigate } from "react-router-dom";
import userInfoState from "states/user";
import authInfoState from "states/auth";
import { CloseIcon } from "components/Icons";

import ROUTES from "config/routes";

type NavMenuProps = {
  isMenuOpen: boolean;
  onMenuClose: () => void;
};

const NavMenu = ({ isMenuOpen, onMenuClose }: NavMenuProps) => {
  const {
    userInfo: { alias, full_name, isAmbassador },
  } = userInfoState();
  const {
    authInfo: { isLoggedIn },
  } = authInfoState();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    onMenuClose();
    navigate(path);
  };

  return (
    <Drawer
      open={isMenuOpen}
      onClose={onMenuClose}
      direction="left"
      className="bg-secondary"
      size={335}
      style={{ background: "#1e1e1e" }}
    >
      <div className="flex flex-col p-6">
        <div className="flex items-center">
          <div className="cursor-pointer" onClick={onMenuClose}>
            <CloseIcon />
          </div>
        </div>
        <p className="mt-8 mb-4 ml-6 font-montserrat font-black text-xl">
          {isLoggedIn ? alias || full_name : "Sign In"}
        </p>
        <hr />
        <p
          className="ml-6 my-4 text-xl font-montserrat cursor-pointer hover:text-primary"
          onClick={() => handleNavigate(ROUTES.LEADERBOARD.path)}
        >
          Leaderboard
        </p>
        {ROUTES.CLAIM_BOARD.enabled && (
          <p
            className="ml-6 my-4 text-xl font-montserrat cursor-pointer hover:text-primary"
            onClick={() => handleNavigate(ROUTES.CLAIM_BOARD.path)}
          >
            Claim Board
          </p>
        )}
        {isAmbassador && ROUTES.AFFILIATE_DASHBOARD.enabled && (
          <p
            className="ml-6 my-4 text-xl font-montserrat cursor-pointer hover:text-primary"
            onClick={() => handleNavigate(ROUTES.AFFILIATE_DASHBOARD.path)}
          >
            Affiliate Dashboard
          </p>
        )}
        {ROUTES.TRADING_APP.enabled && (
          <p
            className="ml-6 my-4 text-xl font-montserrat cursor-pointer hover:text-primary"
            onClick={() => handleNavigate(ROUTES.TRADING_APP.path)}
          >
            Trading App
          </p>
        )}
      </div>
    </Drawer>
  );
};

export default NavMenu;

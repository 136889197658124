import { provider } from "../config/provider";
const blockInfo = {
  blockNumber: 0,
  nextBaseFeePerGas: 0n,
  timestamp: 0,
};

const WAIT_FOR_BLOCK_TIMER = 50;

export const getBlockNumber = (): number => blockInfo.blockNumber;

const handleNewBlock = async (blockNumber: number): Promise<void> => {
  blockInfo.blockNumber = blockNumber;

  // set temporary first
  blockInfo.nextBaseFeePerGas = (blockInfo.nextBaseFeePerGas * 1125n) / 1000n;

  const block = await provider.getBlock(blockNumber);
  blockInfo.timestamp = block.timestamp;
};

export const initBlocksUtil = async (): Promise<void> => {
  provider.on("block", handleNewBlock);
  await handleNewBlock(await provider.getBlockNumber());
};

export const waitForBlock = (blockNumber: number): Promise<void> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (blockInfo.blockNumber >= blockNumber) {
        clearInterval(interval);
        resolve();
      }
    }, WAIT_FOR_BLOCK_TIMER);
  });
};

export const getNextBaseFeePerGas = (): bigint => blockInfo.nextBaseFeePerGas;

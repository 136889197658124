import { useEffect, useState } from "react";
import { getToken } from "service/storage";
import socketClient, { Socket } from "socket.io-client";
import toast from "react-hot-toast";

function useSocket() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const url = `${process.env.REACT_APP_SOCKET_URL_BASE}?token=${getToken()}`;
  useEffect(() => {
    const socket = socketClient(url);
    socket.on("connection", () => {
      setSocket(socket);
      console.log("connected");
    });
    socket.on("message", (message) => {
      const msg = JSON.parse(message);
      if (msg.type === "error") {
        // toast(React.createElement("h1", {}, msg.text), { duration: 100000 });

        toast.error(
          <div dangerouslySetInnerHTML={{ __html: msg.text }}></div>,
          {
            duration: 10000,
            style: {
              maxWidth: "min-content",
            },
          }
        );
      } else if (msg.type === "success") {
        toast.success(
          <div dangerouslySetInnerHTML={{ __html: msg.text }}></div>,
          {
            duration: 10000,
            style: {
              maxWidth: "min-content",
            },
          }
        );
      } else {
        toast(<div dangerouslySetInnerHTML={{ __html: msg.text }}></div>, {
          duration: 10000,
          style: {
            maxWidth: "min-content",
          },
        });
      }
    });
  }, [url]);
  return { socket };
}
export default useSocket;

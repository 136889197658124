import {
  ContractTransaction,
  MaxUint256,
  TransactionResponse,
  ethers,
  parseEther,
} from "ethers";
import { provider } from "../config/provider";
import { toHex } from "../utils/format";
import { erc20 } from "../contracts/erc20";
import {
  DEADBLOCKS_LIMIT,
  DEADBLOCKS_TAX_LIMIT,
  TOKEN_UTILS_ADDRESS,
  WETH_ADDRESS,
} from "../config/constants";
import { tokenUtils } from "../contracts/token-utils";
import { getPairAddress } from "../utils/uniswap";
import { TokenUtils__factory } from "../types/ethers-contracts";
import { getBlockNumber } from "../utils/blocks";
import { PoolRoute, getPoolRouteForTrade } from "../utils/routing";

const SIM_FROM_ADDRESS = "0x0000000000000000000000000000000000000002";

type SimulationResult = {
  output?: string;
  value?: string;
  error?: string;
};

export type SimulationResults = SimulationResult[];

type StateContext = {
  blockNumber?: string;
  transactionIndex?: number;
};

type StateOverride = object;

type BlockOverride = {
  blockNumber?: string;
  baseFee?: string;
};

type SimulationParams = [
  bundle: { transactions: Array<BundleTx>; blockOverride?: BlockOverride },
  stateContext?: StateContext,
  stateOverride?: StateOverride
];

type DebugTraceCallManyParams = [
  bundle: { transactions: Array<BundleTx>; blockOverride?: BlockOverride }[],
  stateContext?: StateContext,
  stateOverride?: StateOverride
];

export type BundleTx = {
  from: string;
  to: string;
  gas: string;
  gas_price: string;
  value: string;
  input: string;
};

export const simulate = async (
  params: SimulationParams
): Promise<SimulationResults> => {
  return provider.send("eth_callMany", params).catch((e) => {
    console.log("simulate eth_callMany", e.message);
    return [];
  });
};

export const simulateDebugTraceCallMany = async (
  params: DebugTraceCallManyParams
): Promise<SimulationResults> => {
  return provider.send("debug_traceCallMany", params).catch((e) => {
    console.log("simulate debug_traceCallMany", e.message);
    return [];
  });
};

export const toSimulateBundleTx = (
  tx: ethers.ContractTransaction,
  from?: string
): BundleTx => {
  return {
    from: from || tx.from,
    to: tx.to,
    gas: "0x100000000",
    gas_price: "0x0",
    value: toHex(tx.value || 0),
    input: tx.data,
  };
};

export type TokenCustomInfo = {
  alreadyLaunched: boolean;
  launching: boolean;
  buyTax: bigint;
  sellTax: bigint;
  maxTx: bigint;
  blacklisting?: boolean;
  poolRoute: PoolRoute;
};

export const getTokenCustomInfo = async (
  tokenAddress: string,
  launchTxs: TransactionResponse[] = []
): Promise<TokenCustomInfo> => {
  const stateBlockNumber = launchTxs[0]?.blockNumber
    ? launchTxs[0]?.blockNumber - 1
    : getBlockNumber();

  const lpAddress = getPairAddress(tokenAddress, WETH_ADDRESS);
  const [
    poolRouteBefore,
    poolRoute,
    getTokenInfoTx,
    getMaxTxTx,
    buyTinyAmountTx,
    approveTx,
  ] = await Promise.all([
    getPoolRouteForTrade(tokenAddress, parseEther("0.1"), false),
    getPoolRouteForTrade(tokenAddress, parseEther("0.1"), false, {
      afterTxs: launchTxs,
    }),
    tokenUtils().getTokenInfo.populateTransaction(tokenAddress, lpAddress),
    tokenUtils().getMaxTx.populateTransaction(tokenAddress, lpAddress),
    tokenUtils().buyTinyAmount.populateTransaction(tokenAddress, lpAddress, {
      value: parseEther("0.1"),
    }),
    erc20(tokenAddress).approve.populateTransaction(
      TOKEN_UTILS_ADDRESS,
      ethers.MaxUint256
    ),
  ]);

  if (poolRoute?.type === "v3") {
    // if v3 assume token is very normal
    return {
      alreadyLaunched: poolRouteBefore ? true : false,
      launching: poolRouteBefore ? false : true,
      buyTax: 0n,
      sellTax: 0n,
      maxTx: MaxUint256,
      poolRoute,
    };
  }

  // const time = Date.now();

  const [buyTinyAmountResult, maxTxResult, tokenInfoResult] = await Promise.all(
    [
      simulate([
        {
          transactions: [toSimulateBundleTx(buyTinyAmountTx, SIM_FROM_ADDRESS)],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: toHex(stateBlockNumber + 1),
          },
        },
        { blockNumber: toHex(stateBlockNumber) },
      ]),
      simulate([
        {
          transactions: [
            ...launchTxs.map((tx) => toSimulateBundleTx(tx)),
            toSimulateBundleTx(approveTx, lpAddress),
            toSimulateBundleTx(approveTx, SIM_FROM_ADDRESS),
            toSimulateBundleTx(getMaxTxTx, SIM_FROM_ADDRESS),
          ],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: toHex(stateBlockNumber + 1),
          },
        },
        { blockNumber: toHex(stateBlockNumber) },
      ]),
      simulate([
        {
          transactions: [
            ...launchTxs.map((tx) => toSimulateBundleTx(tx)),
            toSimulateBundleTx(approveTx, lpAddress),
            toSimulateBundleTx(approveTx, SIM_FROM_ADDRESS),
            toSimulateBundleTx(getTokenInfoTx, SIM_FROM_ADDRESS),
          ],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: toHex(stateBlockNumber + 1),
          },
        },
        { blockNumber: toHex(stateBlockNumber) },
      ]),
    ]
  );

  let tinyAmountBought = 0n;
  let maxTx = 0n;
  let buyTax = 1000n;
  let sellTax = 1000n;

  const iface = new ethers.Interface(TokenUtils__factory.abi);
  if (buyTinyAmountResult[0].output) {
    const buyTinyAmountResultOutput = iface.decodeFunctionResult(
      "buyTinyAmount",
      buyTinyAmountResult[0].output
    );
    tinyAmountBought = buyTinyAmountResultOutput[0];
  }

  if (maxTxResult[maxTxResult.length - 1].output) {
    const maxTxResultOutput = iface.decodeFunctionResult(
      "getMaxTx",
      maxTxResult[maxTxResult.length - 1].output
    );
    maxTx = maxTxResultOutput[0];
  }

  if (tokenInfoResult[tokenInfoResult.length - 1].output) {
    const tokenInfoResultOutput = iface.decodeFunctionResult(
      "getTokenInfo",
      tokenInfoResult[tokenInfoResult.length - 1].output
    );
    buyTax =
      tokenInfoResultOutput[0] > 1000n ? 1000n : tokenInfoResultOutput[0];
    sellTax =
      tokenInfoResultOutput[1] > 1000n ? 1000n : tokenInfoResultOutput[1];
  }

  const info = {
    alreadyLaunched: !!tinyAmountBought,
    launching: !tinyAmountBought && buyTax < 1000n,
    buyTax,
    sellTax,
    maxTx,
    poolRoute,
  };

  // console.log('getTokenCustomInfo simulation', Date.now() - time);

  return info;
};

export const getTokenCustomInfoWithBlockOffset = async (
  tokenAddress: string,
  launchTxs: TransactionResponse[] = [],
  blockOffset = 1
): Promise<TokenCustomInfo> => {
  const blockNumber = launchTxs[0]?.blockNumber
    ? toHex(launchTxs[0]?.blockNumber - 1)
    : "latest";
  const offsetBlockNumber = toHex(getBlockNumber() + blockOffset);

  const lpAddress = getPairAddress(tokenAddress, WETH_ADDRESS);
  const [
    poolRouteBefore,
    poolRoute,
    getTokenInfoTx,
    getMaxTxTx,
    buyTinyAmountTx,
    approveTx,
  ] = await Promise.all([
    getPoolRouteForTrade(tokenAddress, parseEther("0.1"), false),
    getPoolRouteForTrade(tokenAddress, parseEther("0.1"), false, {
      afterTxs: launchTxs,
      blockNumber: launchTxs[0]?.blockNumber
        ? launchTxs[0]?.blockNumber - 1
        : undefined,
    }),
    tokenUtils().getTokenInfo.populateTransaction(tokenAddress, lpAddress),
    tokenUtils().getMaxTx.populateTransaction(tokenAddress, lpAddress),
    tokenUtils().buyTinyAmount.populateTransaction(tokenAddress, lpAddress, {
      value: parseEther("0.05"),
    }),
    erc20(tokenAddress).approve.populateTransaction(
      TOKEN_UTILS_ADDRESS,
      ethers.MaxUint256
    ),
  ]);

  if (poolRoute?.type === "v3") {
    // if v3 assume token is very normal
    return {
      alreadyLaunched: poolRouteBefore ? true : false,
      launching: poolRouteBefore ? false : true,
      buyTax: 0n,
      sellTax: 0n,
      maxTx: MaxUint256,
      poolRoute,
    };
  }

  // const time = Date.now();

  const [
    buyTinyAmountResult,
    maxTxResult,
    tokenInfoResult,
    tokenInfoResultBlacklist,
  ] = await Promise.all([
    simulate([
      {
        transactions: [toSimulateBundleTx(buyTinyAmountTx, SIM_FROM_ADDRESS)],
        blockOverride: { baseFee: toHex(0) },
      },
      { blockNumber },
    ]),
    simulateDebugTraceCallMany([
      [
        {
          transactions: [...launchTxs.map((tx) => toSimulateBundleTx(tx))],
          blockOverride: { baseFee: toHex(0) },
        },
        {
          transactions: [
            toSimulateBundleTx(approveTx, lpAddress),
            toSimulateBundleTx(approveTx, SIM_FROM_ADDRESS),
            toSimulateBundleTx(getMaxTxTx, SIM_FROM_ADDRESS),
          ],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: offsetBlockNumber,
          },
        },
      ],
      { blockNumber },
      { tracerConfig: { onlyTopCall: true }, tracer: "callTracer" },
    ]),
    simulateDebugTraceCallMany([
      [
        {
          transactions: [...launchTxs.map((tx) => toSimulateBundleTx(tx))],
          blockOverride: { baseFee: toHex(0) },
        },
        {
          transactions: [
            toSimulateBundleTx(approveTx, lpAddress),
            toSimulateBundleTx(approveTx, SIM_FROM_ADDRESS),
            toSimulateBundleTx(getTokenInfoTx, SIM_FROM_ADDRESS),
          ],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: toHex(getBlockNumber() + DEADBLOCKS_LIMIT),
          },
        },
      ],
      { blockNumber },
      { tracerConfig: { onlyTopCall: true }, tracer: "callTracer" },
    ]),
    simulateDebugTraceCallMany([
      [
        {
          transactions: [
            ...launchTxs.map((tx) => toSimulateBundleTx(tx)),
            toSimulateBundleTx(approveTx, lpAddress),
            toSimulateBundleTx(approveTx, SIM_FROM_ADDRESS),
            toSimulateBundleTx(getTokenInfoTx, SIM_FROM_ADDRESS),
          ],
          blockOverride: { baseFee: toHex(0) },
        },
        {
          transactions: [toSimulateBundleTx(getTokenInfoTx, SIM_FROM_ADDRESS)],
          blockOverride: {
            baseFee: toHex(0),
            blockNumber: toHex(getBlockNumber() + DEADBLOCKS_LIMIT),
          },
        },
      ],
      { blockNumber },
      { tracerConfig: { onlyTopCall: true }, tracer: "callTracer" },
    ]),
  ]);

  let tinyAmountBought = 0n;
  let maxTx = 0n;
  let buyTax = 1000n;
  let sellTax = 1000n;
  let blacklisting = false;

  const iface = new ethers.Interface(TokenUtils__factory.abi);
  if (buyTinyAmountResult[0].output) {
    const buyTinyAmountResultOutput = iface.decodeFunctionResult(
      "buyTinyAmount",
      buyTinyAmountResult[0].output
    );
    tinyAmountBought = buyTinyAmountResultOutput[0];
  }

  if (!maxTxResult[maxTxResult.length - 1].error) {
    const maxTxResultOutput = iface.decodeFunctionResult(
      "getMaxTx",
      maxTxResult[maxTxResult.length - 1].output
    );
    maxTx = maxTxResultOutput[0];
  }

  if (!tokenInfoResult[tokenInfoResult.length - 1].error) {
    const tokenInfoResultOutput = iface.decodeFunctionResult(
      "getTokenInfo",
      tokenInfoResult[tokenInfoResult.length - 1].output
    );
    buyTax =
      tokenInfoResultOutput[0] > 1000n ? 1000n : tokenInfoResultOutput[0];
    sellTax =
      tokenInfoResultOutput[1] > 1000n ? 1000n : tokenInfoResultOutput[1];
  }

  if (!tokenInfoResultBlacklist[tokenInfoResultBlacklist.length - 1].error) {
    const tokenInfoResultBlacklistOutput = iface.decodeFunctionResult(
      "getTokenInfo",
      tokenInfoResultBlacklist[tokenInfoResultBlacklist.length - 1].output
    );
    const sellTaxAfter =
      tokenInfoResultBlacklistOutput[1] > 1000n
        ? 1000n
        : tokenInfoResultBlacklistOutput[1];

    if (
      sellTaxAfter >= DEADBLOCKS_TAX_LIMIT && // first buy and sell after deadblocks
      sellTax < DEADBLOCKS_TAX_LIMIT // buy and sell after deadblocks
    ) {
      blacklisting = true;
    }
  }

  const info = {
    alreadyLaunched: !!tinyAmountBought,
    launching: !tinyAmountBought && buyTax < 1000n,
    buyTax,
    sellTax,
    maxTx,
    poolRoute,
    blacklisting,
  };

  // console.log(
  //   'getTokenCustomInfoWithBlockOffset simulation',
  //   Date.now() - time,
  // );

  return info;
};

export const simulateTxs = async (
  txs: ContractTransaction[],
  blockNumber = 0,
  stateOverrides?: object
): Promise<SimulationResults> => {
  const bundleTxs = txs.map((tx) => toSimulateBundleTx(tx));

  const result = await simulate([
    {
      transactions: bundleTxs,
      blockOverride: { baseFee: toHex(0) },
    },
    { blockNumber: blockNumber ? toHex(blockNumber) : toHex(getBlockNumber()) },
    stateOverrides,
  ]);

  return result.map(r => ({
    ...r,
    output: r.output ?? r.value
  }));
};

type TableProps = {
  containerStyle?: string;
  cardTitle: string;
  data?: Array<{
    userId: string;
    rev_bonus: number;
    next_rev_bonus: number;
    daily_volume: number;
    daily_earning: number;
    total_volume: number;
    total_earning: number;
    claimable_earning: number;
  }>;
};

const ReferralTable = ({ containerStyle, cardTitle, data }: TableProps) => {
  return (
    <div
      className={`${containerStyle} rounded-2xl text-center bg-secondary px-2 sm:px-4`}
    >
      <div className="flex text-white items-center justify-center h-24">
        <h1 className="font-bold py-2 sm:py-4 bg-secondary text-white font-transducer m-3 text-lg sm:text-2xl">
          {cardTitle}
        </h1>
      </div>
      <div className="font-transducer">
        <div className="overflow-auto relative">
          <div className="overflow-auto relative max-h-[640px]">
            <table className="w-full bg-secondary text-xs sm:text-sm">
              <thead>
                <tr className="sticky top-0 bg-selectedBrown z-10">
                  <th className="px-4 sm:px-6 py-3">Rank</th>
                  <th className="px-4 sm:px-6 py-3">24h Vol</th>
                  <th className="px-4 sm:px-6 py-3">24h Earnings</th>
                  <th className="px-4 sm:px-6 py-3">Lifetime Vol</th>
                  <th className="px-4 sm:px-6 py-3">Lifetime Earnings</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((b, a) => a.total_volume - b.total_volume)
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 even:bg-transGray"
                    >
                      <td className="px-4 sm:px-6 py-4">{index + 1}</td>
                      <td className="px-4 sm:px-6 py-4">
                        {"$" + item.daily_volume.toFixed(2)}
                      </td>
                      <td className="px-4 sm:px-6 py-4">
                        {"$" + item.daily_earning.toFixed(2)}
                      </td>
                      <td className="px-4 sm:px-6 py-4">
                        {"$" + item.total_volume.toFixed(2)}
                      </td>
                      <td className="px-4 sm:px-6 py-4">
                        {"$" + item.total_earning.toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralTable;

import { atom } from "recoil";
import { useRecoilState } from "recoil";

interface User {
  id: bigint;
  full_name: string;
  username: string;
  alias: string;
  referral_id: string;
  alpha_user: boolean;
  isAmbassador: boolean;
}

export interface UserStatistics {
  user_id: bigint;
  trading_point: bigint;
  today_trading_point_gain: bigint;
  daily_trade_count: number;
  daily_trade_volume_usd: string;
  daily_trade_profit_usd: string;
  total_trade_count: number;
  total_trade_volume_usd: string;
  total_manual_order: number;
  total_limit_order: number;
  total_snipe_order: number;
  total_profit: string;
  total_loss: string;
  total_invite: number;
  completed_daily_challenges: number[];
  rank: number;
  alias: string;
  full_name: string;
  volumeMultiplier: number;
}

const userInfoState = atom<User>({
  key: "user-info",
  default: {
    id: 0n,
    full_name: "",
    username: "",
    alias: "",
    referral_id: "",
    alpha_user: false,
    isAmbassador: false,
  },
});

const userStatisticsState = atom<UserStatistics>({
  key: "user-statistics-info",
  default: {
    user_id: 0n,
    trading_point: 0n,
    today_trading_point_gain: 0n,
    daily_trade_count: 0,
    daily_trade_volume_usd: "0",
    daily_trade_profit_usd: "0",
    total_trade_count: 0,
    total_trade_volume_usd: "0",
    total_manual_order: 0,
    total_limit_order: 0,
    total_snipe_order: 0,
    total_profit: "0",
    total_loss: "0",
    total_invite: 0,
    completed_daily_challenges: [],
    rank: 0,
    alias: "",
    full_name: "",
    volumeMultiplier: 1,
  },
});

export default function State() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userStatistics, setUserStatistics] =
    useRecoilState(userStatisticsState);

  return { userInfo, userStatistics, setUserInfo, setUserStatistics };
}

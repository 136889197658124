import { parseUnits } from "ethers";
import http from "./http";

interface BuyOrder {
  wallets: string;
  amount: string;
  token: string;
  slippage: string;
  extraGas: string;
}
interface SellOrder {
  wallets: string;
  percentage: number;
  token: string;
  slippage: string;
  extraGas: string;
}
export const postBuyOrder = ({
  wallets,
  amount,
  token,
  slippage,
  extraGas,
}: BuyOrder) => {
  return http().post("/manual/buy", {
    wallets,
    amount,
    token,
    slippage: +slippage,
    extraGas: parseUnits(extraGas, "gwei").toString(),
  });
};
export const postSellOrder = ({
  wallets,
  percentage,
  token,
  slippage,
  extraGas,
}: SellOrder) => {
  return http().post("/manual/sell", {
    wallets,
    percentage,
    token,
    slippage: +slippage,
    extraGas: parseUnits(extraGas, "gwei").toString(),
  });
};

import { useEffect, useState } from "react";
import styled from "styled-components";
import currentPairState from "states/currentPair";
import toast from "react-hot-toast";
import { TokenInfo, getTokenContractLink, getTokenInfo } from "utils/token";
import {
  formatBigNumber,
  formatEtherFixed,
  formatPercent,
  formatPrice,
} from "utils/format";
import { MaxUint256 } from "ethers";
import { getEllipsisAddress } from "utils/address";

import Card from "components/Card";
import colors from "theme/colors";

const Styled = {
  Fields: styled.div`
    padding: 0 20px;
  `,
  Field: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.transGray};
    padding: 10px 0;
  `,
  Label: styled.span`
    color: ${colors.darkGray};
    font-size: 11px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  `,
  Price: styled.span`
    color: ${colors.white};
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
  `,
  AddressLabel: styled.p`
    color: ${colors.darkGray};
    font-size: 13px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 15px 0;
  `,
  AddressWrapper: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Address: styled.div`
    display: flex;
    align-items: center;
    color: ${colors.white};
    font-size: 13px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 500;
    line-height: normal;
  `,
  Actions: styled.div`
    display: flex;
    gap: 5px;
  `,
  Copy: styled.button`
    color: ${colors.white};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 5px 7px;
    border-radius: 9px;
    background: ${colors.transGray};
    text-transform: uppercase;
  `,
  Out: styled.a`
    padding: 5px 7px;
    border-radius: 9px;
    background: ${colors.transGray};
  `,
};

const OpenLinkSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M6.25 3.75H3.75C3.05964 3.75 2.5 4.30964 2.5 5V11.25C2.5 11.9404 3.05964 12.5 3.75 12.5H10C10.6904 12.5 11.25 11.9404 11.25 11.25V8.75M8.75 2.5H12.5M12.5 2.5V6.25M12.5 2.5L6.25 8.75"
        stroke="white"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Info = () => {
  const { currentPair } = currentPairState();
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>(null);
  useEffect(() => {
    getTokenInfo(currentPair.quoteToken).then(setTokenInfo);
  }, [currentPair.quoteToken]);

  const handleCopyCA = (address: string) => () => {
    if ("clipboard" in navigator) {
      navigator.clipboard
        .writeText(address)
        .then(() => toast("Copied", { duration: 2000 }));
    } else {
      document.execCommand("copy", true, address);
    }
  };

  return (
    <Card
      cardTitle={`${tokenInfo?.symbol ?? "Token"} Info ${(
        currentPair?.labels ?? []
      ).join(", ")}`}
      containerStyle="rounded-lg bg-secondary"
    >
      {tokenInfo && (
        <Styled.Fields>
          <Styled.Field>
            <Styled.Label>USD Price</Styled.Label>
            <Styled.Price style={{ fontSize: "18px" }}>
              ${formatPrice(tokenInfo.priceUSD)}
            </Styled.Price>
          </Styled.Field>
          <Styled.Field>
            <Styled.Label>ETH Price</Styled.Label>
            <Styled.Price>Ξ{formatPrice(tokenInfo.priceEther)}</Styled.Price>
          </Styled.Field>
          <Styled.Field>
            <Styled.Label>LIQUIDITY (ETH)</Styled.Label>
            <Styled.Price>
              Ξ{formatEtherFixed(tokenInfo.liquidityETH)}
            </Styled.Price>
          </Styled.Field>
          <Styled.Field>
            <Styled.Label>MARKET CAP</Styled.Label>
            <Styled.Price>${formatBigNumber(tokenInfo.marketCap)}</Styled.Price>
          </Styled.Field>
          <Styled.Field>
            <Styled.Label>BUY/SELL TAX</Styled.Label>
            <Styled.Price>
              {Number(tokenInfo.buyTax) / 10}/{Number(tokenInfo.sellTax) / 10}%
            </Styled.Price>
          </Styled.Field>
          <Styled.Field>
            <Styled.Label>MAX Tx</Styled.Label>
            <Styled.Price>
              {tokenInfo.maxTx === MaxUint256
                ? "100"
                : formatPercent(
                    (Number(tokenInfo.maxTx) / Number(tokenInfo.totalSupply)) *
                      100,
                    2
                  )}
              %
            </Styled.Price>
          </Styled.Field>

          <Styled.AddressLabel>Token Contract Address</Styled.AddressLabel>
          <Styled.AddressWrapper>
            <Styled.Address>
              {getEllipsisAddress(currentPair.quoteToken)}
            </Styled.Address>
            <Styled.Actions>
              <Styled.Copy onClick={handleCopyCA(currentPair.quoteToken)}>
                Copy
              </Styled.Copy>
              <Styled.Out
                href={getTokenContractLink(currentPair.quoteToken)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenLinkSvg />
              </Styled.Out>
            </Styled.Actions>
          </Styled.AddressWrapper>

          <Styled.AddressLabel>Token Lp Address</Styled.AddressLabel>
          <Styled.AddressWrapper>
            <Styled.Address>
              {getEllipsisAddress(currentPair.pairAddress)}
            </Styled.Address>
            <Styled.Actions>
              <Styled.Copy onClick={handleCopyCA(currentPair.pairAddress)}>
                Copy
              </Styled.Copy>
              <Styled.Out
                href={getTokenContractLink(currentPair.pairAddress)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenLinkSvg />
              </Styled.Out>
            </Styled.Actions>
          </Styled.AddressWrapper>
        </Styled.Fields>
      )}
    </Card>
  );
};

export default Info;

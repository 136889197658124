import { decodeBytes32String, parseEther } from "ethers";
import { DAI_ADDRESS, WETH_ADDRESS } from "../config/constants";
import { erc20 } from "../contracts/erc20";
import { v2pair } from "../contracts/v2pair";
import { getTokenCustomInfo } from "../service/simulation";
import { normalizeAddress } from "./address";
import { getPairAddress } from "./uniswap";
import { provider } from "../config/provider";
import { getPoolRouteForTrade } from "./routing";
import { Wallet } from "states/wallets";

const nonStandardTokens = ["0xdAC17F958D2ee523a2206206994597C13D831ec7"];

export const checkIsToken = async (address: string): Promise<boolean> => {
  address = normalizeAddress(address);
  const token = erc20(address);

  const totalSupply = await token.totalSupply().catch((e) => {
    console.error("checkIsToken fail", e);
    return -1;
  });

  if (totalSupply !== -1) return true;

  return false;
};

export const checkIsLaunched = async (address: string): Promise<boolean> => {
  address = normalizeAddress(address);

  if (nonStandardTokens.includes(address)) return true;

  const tokenInfo = await getTokenCustomInfo(address);

  if (tokenInfo?.buyTax < 1000) return true;

  return false;
};

export const getEtherPriceUSD = async (): Promise<number> => {
  // get ether price up to 4 decimals from dai/weth pair
  const pairAddress = getPairAddress(WETH_ADDRESS, DAI_ADDRESS);
  const [wethBalance, daiBalance] = await Promise.all([
    erc20(WETH_ADDRESS).balanceOf(pairAddress),
    erc20(DAI_ADDRESS).balanceOf(pairAddress),
  ]);
  return Number(daiBalance) / Number(wethBalance);
};

export const getTokenPriceETH = async (address: string): Promise<number> => {
  const [poolRoute, decimals] = await Promise.all([
    getPoolRouteForTrade(address, parseEther("0.05"), false),
    erc20(address).decimals(),
  ]);

  if (!poolRoute) return 0;
  if (poolRoute.liquidityValueETH < parseEther("0.1")) return 0;

  return (
    Number(poolRoute.amountIn * BigInt(10 ** Number(decimals))) /
    Number(poolRoute.amountOut * BigInt(10 ** 18))
  );
};

export const getTokenPriceUSD = async (address: string): Promise<number> => {
  const [tokenPriceETH, etherPrice] = await Promise.all([
    getTokenPriceETH(address),
    getEtherPriceUSD(),
  ]);

  return tokenPriceETH * etherPrice;
};

export type TokenBasicInfo = {
  address: string;
  lpAddress: string;
  name: string;
  symbol: string;
  decimals: number;
};

export type TokenInfo = {
  address: string;
  lpAddress: string;
  name: string;
  symbol: string;
  decimals: number;
  totalSupply: bigint;
  priceEther: number;
  priceUSD: number;
  marketCap: number;
  liquidityETH: bigint;
  liquidityToken: bigint;
  buyTax: bigint;
  sellTax: bigint;
  maxTx: bigint;
};

const tokenBasicInfoMap = new Map<string, TokenBasicInfo>();

export const getTokenBasicInfo = async (
  address: string
): Promise<TokenBasicInfo> => {
  const token = erc20(address);

  if (!tokenBasicInfoMap.has(address)) {
    const lpAddress = getPairAddress(address, WETH_ADDRESS);

    // eslint-disable-next-line prefer-const
    let [name, symbol, decimals] = await Promise.all([
      token.name().catch((e) => decodeBytes32String(e.value)),
      token.symbol().catch((e) => decodeBytes32String(e.value)),
      token.decimals(),
    ]);

    // cap name and symbol length
    if (name.length > 20) {
      name = name.slice(0, 20) + "...";
    }
    if (symbol.length > 10) {
      symbol = symbol.slice(0, 10) + "...";
    }

    tokenBasicInfoMap.set(address, {
      address,
      lpAddress,
      name,
      symbol,
      decimals: Number(decimals),
    });
  }
  return tokenBasicInfoMap.get(address);
};

export const getTokenInfo = async (address: string): Promise<TokenInfo> => {
  const token = erc20(address);
  const lpAddress = getPairAddress(address, WETH_ADDRESS);

  const [
    { name, symbol, decimals },
    totalSupply,
    priceEther,
    priceUSD,
    liquidityETH,
    liquidityToken,
  ] = await Promise.all([
    getTokenBasicInfo(address),
    token.totalSupply(),
    getTokenPriceETH(address),
    getTokenPriceUSD(address),
    erc20(WETH_ADDRESS).balanceOf(lpAddress),
    token.balanceOf(lpAddress),
  ]);
  const { buyTax, sellTax, maxTx } = await getTokenCustomInfo(address);

  return {
    address,
    name,
    symbol,
    lpAddress,
    totalSupply,
    decimals: Number(decimals),
    priceEther,
    priceUSD,
    marketCap:
      (priceUSD * Number(totalSupply)) / Math.pow(10, Number(decimals)),
    liquidityETH,
    liquidityToken,
    buyTax,
    sellTax,
    maxTx,
  };
};

export const getChartLink = (tokenAddress: string): string => {
  const lpAddress = getPairAddress(tokenAddress, WETH_ADDRESS);

  return `https://www.dextools.io/app/en/ether/pair-explorer/${lpAddress}`;
};

export const getTokenExternalLinksText = (tokenAddress: string): string => {
  const lpAddress = getPairAddress(tokenAddress, WETH_ADDRESS);

  return `<a href="https://etherscan.io/address/${tokenAddress}">Contract</a> | <a href="https://dexscreener.com/ethereum/${lpAddress}">Dexscreener</a> | <a href="https://www.dextools.io/app/en/ether/pair-explorer/${lpAddress}">Dextools</a>`;
};

export const getTokenContractLink = (tokenAddress: string): string => {
  return `https://etherscan.io/address/${tokenAddress}`;
};

export const getWalletEthBalances = async (
  wallets: Wallet[]
): Promise<bigint[]> => {
  const walletEthBals = await Promise.all(
    wallets.map(async ({ address }) => {
      const balance = await provider.getBalance(address);
      return balance;
    })
  );

  return walletEthBals;
};

export const getTokenAddressFromLpToken = async (
  address: string
): Promise<string> => {
  const token0 = await v2pair(address).token0();
  const token1 = await v2pair(address).token1();
  return token0 === WETH_ADDRESS ? token1 : token0;
};

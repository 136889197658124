import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";
import AppContext from "context";
import { getBlockNumber } from "utils/blocks";

import Leaderboard from "pages/Leaderboard";
import AffiliateDashboard from "pages/AffiliateDashboard";
import ClaimBoard from "pages/ClaimBoard";
import TradingApp from "pages/TradingApp";
import NotFound from "pages/NotFound";
import Layout from "components/Layout";
import ProtectedRoute from "components/ProtectedRoute";
import ROUTES from "config/routes";

import "react-modern-drawer/dist/index.css";

const queryClient = new QueryClient();
let timerId: any;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    timerId = setInterval(() => {
      if (getBlockNumber() !== 0) {
        setIsLoading(false);
        clearInterval(timerId);
      }
    }, 300);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={1}>
        <RecoilRoot>
          <BrowserRouter>
            <Routes>
              <Route
                path={ROUTES.LEADERBOARD.path}
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Leaderboard />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              {ROUTES.AFFILIATE_DASHBOARD.enabled && (
                <Route
                  path={ROUTES.AFFILIATE_DASHBOARD.path}
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <AffiliateDashboard />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
              )}
              {ROUTES.CLAIM_BOARD.enabled && (
                <Route
                  path={ROUTES.CLAIM_BOARD.path}
                  element={
                    <Layout>
                      <ClaimBoard />
                    </Layout>
                  }
                />
              )}
              {ROUTES.TRADING_APP.enabled && (
                <Route
                  path={ROUTES.TRADING_APP.path}
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <TradingApp />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
        <Toaster />
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;

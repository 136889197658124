import userInfoState from "states/user";
import { Challenge } from "pages/Leaderboard";
import CircularProgressBar from "./CircularProgressBar";

const DailyChallenges = ({ challenges }: { challenges: Challenge[] }) => {
  const { userStatistics } = userInfoState();
  const challengeIDs = challenges.map((ch) => ch.id);
  const completed_daily_challenges =
    userStatistics.completed_daily_challenges || [];

  const percentage =
    (challengeIDs.filter((id) => completed_daily_challenges.includes(id))
      .length /
      challengeIDs.length) *
    100;
  const isCompleted = (id: number) => completed_daily_challenges.includes(id);

  return (
    <div className="flex-1 flex flex-col md:flex-row font-transducer">
      <div className="w-full md:w-4/5 flex flex-col p-6 md:p-8">
        <p className="text-gray font-bold uppercase text-base">
          COMPLETE BOTH FOR REWARD
        </p>
        <div className="flex justify-center flex-col flex-1 text-sm">
          {challenges.map((ch, index) => (
            <div key={ch.id} className="my-1">
              <p className="uppercase text-gray font-bold my-1">
                Challenge {index + 1}
              </p>
              <div
                className={
                  "flex justify-between" +
                  (isCompleted(ch.id) ? " text-orange" : "")
                }
              >
                <li>{ch.description}</li>
                <input
                  type="checkbox"
                  checked={isCompleted(ch.id)}
                  readOnly
                  className="w-4 h-4 rounded text-orange bg-transGray border-none"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col pb-4 md:p-8 flex-1 items-center">
        <p className="text-gray font-bold uppercase text-base">REWARDS</p>
        <div className="flex justify-center items-center flex-1">
          <CircularProgressBar text="100" percentage={percentage} />
        </div>
      </div>
    </div>
  );
};

export default DailyChallenges;

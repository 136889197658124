import http from "./http";

export const getUserInfo = () => {
  return http().get("/user");
};

export const getWallets = () => {
  return http().get("/user/wallets");
};
export const getTokenInfo = (token: string) => {
  return http().post("/user/tokenInfo", { token });
};

export const getHistory = () => {
  return http().get("/user/history");
};
export const getPosition = () => {
  return http().get("/user/position");
};

export const getPositionByToken = (token: string) => {
  return http().get(`/user/positionByToken?token=${token}`);
};

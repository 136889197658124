const colors = {
  black: '#1e1e1e',
  transGray: '#2f2f2f',
  darkGray: '#606060',
  gray: '#8E8E8E',
  lightGray: '#979797',
  white: '#fff',
  greenAlpha: '#C7FFAC',
  green: '#09BE51',
  redAlpha: '#FF9090',
  red: '#CD1919'
}

export default colors
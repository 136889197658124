import walletsState from "states/wallets";
import { formatBalance } from "utils/format";
import styled from "styled-components";

const Styled = {
  Wrapper: styled.div`
    padding: 0px 12px 10px;
    display: flex;
    justify-content: space-between;
    color: #fff;
  `,

  Item: styled.div<{ $active?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    background: ${({ $active }: { $active?: boolean }) =>
      $active ? "#F1651D" : "#2F2F2F"};
    cursor: pointer;
    border-radius: 8.274px;
    flex: 1;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  `,
  Title: styled.span`
    font-size: 9px;
    font-weight: 700;
    font-family: "Transducer Test";
  `,

  Desc: styled.span`
    font-size: 6.5px;
  `,
};

const Wallets = () => {
  const { wallets, setWallets } = walletsState();
  const handleWalletSelect = (index: number, isSelected: boolean) => {
    const selectedCount = wallets.filter((item) => item.isSelected).length;
    if (selectedCount === 1 && isSelected) return;
    setWallets(
      wallets.map((item) =>
        item.wallet_index === index
          ? { ...item, isSelected: !item.isSelected }
          : item
      )
    );
  };
  return (
    <>
      <Styled.Wrapper>
        {wallets.map((wallet) => (
          <Styled.Item
            $active={wallet.isSelected}
            key={wallet.wallet_index}
            onClick={() =>
              handleWalletSelect(wallet.wallet_index, wallet.isSelected)
            }
            className="mt-3"
          >
            <Styled.Title>W{wallet.wallet_index}</Styled.Title>
            <Styled.Desc>Ξ{formatBalance(wallet.balance, 18, 3)}</Styled.Desc>
          </Styled.Item>
        ))}
      </Styled.Wrapper>
    </>
  );
};

export default Wallets;

import React from "react";

type CardProps = {
  containerStyle?: string;
  cardTitle: string;
  children: React.ReactNode;
  isSticky?: boolean;
};

const Card = ({
  containerStyle,
  cardTitle,
  children,
  isSticky = false,
}: CardProps) => {
  const stickyHeader = isSticky ? " sticky top-0 z-10" : "";
  return (
    <div className={containerStyle + " rounded-t-2xl"}>
      <div>
        <h1
          className={
            "text-lg font-bold uppercase p-4 rounded-t-2xl bg-transGray text-white font-transducer" +
            stickyHeader
          }
        >
          {cardTitle}
        </h1>
      </div>
      {children}
    </div>
  );
};

export default Card;

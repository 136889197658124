const ROUTES: Record<
  string,
  {
    path: string;
    enabled?: boolean;
  }
> = {
  LEADERBOARD: {
    path: "/",
  },
  CLAIM_BOARD: {
    path: "/claim-board",
    enabled: true,
  },
  AFFILIATE_DASHBOARD: {
    path: "/affiliate-dashboard",
    enabled: true,
  },
  TRADING_APP: {
    path: "/app",
    enabled: false,
  },
};

export default ROUTES;

import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import colors from "theme/colors";

const Styled = {
  Button: styled.button`
    display: flex;
    align-items: center;
  `,
  MenuWrapper: styled.div`
    position: absolute;
    left: 0;
    z-index: 10;
    margin-top: 2px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid ${colors.lightGray};
    background: ${colors.black};
  `,
  MenuItem: styled.div<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    background: ${({ $active }: { $active?: boolean }) =>
      $active ? colors.darkGray : "transparent"};
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
  `,
};

type ItemType = {
  value: string;
  label: string;
  displayLabel?: string;
  icon?: React.ReactNode;
};

type Props = {
  items: ItemType[];
  value?: string;
  onChange?: (value: string) => void;
  wrapperClassName?: string;
};

const ChevronDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" fill="none">
    <path
      fill="#fff"
      d="M7.953 1.245 4.13 6.038a.172.172 0 0 1-.26 0L.047 1.244a.238.238 0 0 1-.02-.253.167.167 0 0 1 .147-.088h7.652c.061 0 .118.034.146.088a.239.239 0 0 1-.02.254Z"
    />
  </svg>
);

const defaultItem: ItemType = {
  value: "",
  label: "",
  displayLabel: "",
};

const Dropdown = ({ items, value, onChange, wrapperClassName }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpenMenu = () => {
    setOpen(!isOpen);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setOpen]);

  const handleSelectItem = (item: ItemType) => () => {
    onChange(item.value);
    setOpen(false);
  };

  const selectedItem =
    items.find((item) => item.value === value) ?? defaultItem;

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <Styled.Button onClick={handleOpenMenu}>
          <span className="mr-2">{selectedItem.displayLabel ?? ""}</span>
          <ChevronDownIcon />
        </Styled.Button>
      </div>
      {isOpen ? (
        <Styled.MenuWrapper className={wrapperClassName ?? "w-40"}>
          {items.map((item, index) => {
            return (
              <Styled.MenuItem
                key={`menu-item-${item.value}-${index}`}
                $active={value === item.value}
                onClick={handleSelectItem(item)}
              >
                {item.icon ? <span className="mr-2">{item.icon}</span> : null}
                {item.label}
              </Styled.MenuItem>
            );
          })}
        </Styled.MenuWrapper>
      ) : null}
    </div>
  );
};

export default Dropdown;

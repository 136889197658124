import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import BuyOrder from "./Buy";
import SellOrder from "./Sell";
import LimitBuyOrder from "./LimitBuy";
import LimitSellOrder from "./LimitSell";
import { useState } from "react";
import SnipeOrder from "./Snipe";

const Styled = {
  Wrapper: styled.div`
    padding: 12px 6px;
  `,
  Tabs: styled.div`
    display: flex;
    height: 32.267px;
    flex-shrink: 0;
    border-radius: 8.274px;
    border: 1px solid #f1651d;
    background: rgba(241, 101, 29, 0.1);
    color: #fff;
    width: 100%;
    font-family: "Transducer Test";
    font-size: 10px;
  `,

  FirstTab: styled.div<{ $active?: boolean }>`
    flex: 1;
    display: flex;
    height: 32px;
    padding: 8.274px;
    justify-content: center;
    align-items: center;
    gap: 8.274px;
    flex-shrink: 0;
    border-radius: 8.274px 0px 0px 8.274px;
    background: ${({ $active }: { $active?: boolean }) =>
      $active ? "#F1651D" : "inherit"};
    cursor: pointer;
    border-right: 1px solid rgba(241, 101, 29, 0.15);
  `,

  MidTabs: styled.div<{ $active?: boolean }>`
    flex: 1;
    display: flex;
    height: 32px;
    padding: 8.274px;
    justify-content: center;
    align-items: center;
    gap: 8.274px;
    flex-shrink: 0;
    cursor: pointer;
    background: ${({ $active }: { $active?: boolean }) =>
      $active ? "#F1651D" : "inherit"};
    border-right: 1px solid rgba(241, 101, 29, 0.15);
    text-align: center;
  `,

  LastTab: styled.div<{ $active?: boolean }>`
    flex: 1;
    display: flex;
    height: 32.06px;
    padding: 8.274px;
    justify-content: center;
    align-items: center;
    gap: 8.274px;
    flex-shrink: 0;
    cursor: pointer;
    background: ${({ $active }: { $active?: boolean }) =>
      $active ? "#F1651D" : "inherit"};
    border-radius: 0px 8.274px 8.274px 0px;
    text-align: center;
    text-transform: uppercase;
  `,
};

const Order = () => {
  const [active, setActive] = useState(2);

  return (
    <Styled.Wrapper className="rounded-lg bg-secondary w-full">
      <Styled.Tabs>
        <Styled.FirstTab $active={active === 0} onClick={() => setActive(0)}>
          BUY
        </Styled.FirstTab>
        <Styled.MidTabs $active={active === 1} onClick={() => setActive(1)}>
          SELL
        </Styled.MidTabs>
        <Styled.MidTabs $active={active === 2} onClick={() => setActive(2)}>
          LIMIT BUY
        </Styled.MidTabs>
        <Styled.MidTabs $active={active === 3} onClick={() => setActive(3)}>
          LIMIT SELL
        </Styled.MidTabs>
        <Styled.LastTab $active={active === 4} onClick={() => setActive(4)}>
          Snipe Order
        </Styled.LastTab>
      </Styled.Tabs>
      {active === 0 && <BuyOrder />}
      {active === 1 && <SellOrder />}
      {active === 2 && <LimitBuyOrder />}
      {active === 3 && <LimitSellOrder />}
      {active === 4 && <SnipeOrder />}
    </Styled.Wrapper>
  );
};

export default Order;

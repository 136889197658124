/* eslint-disable react-hooks/exhaustive-deps */
import Order from "components/Order";
import DexChart from "components/Chart";
import Position from "components/Position";
import { useEffect } from "react";
import { getWallets } from "apis/user";
import walletsState, { Wallet } from "states/wallets";
import useSocket from "hooks/useSocket";
import { getWalletEthBalances } from "utils/token";
import Info from "components/Info";

function TradingApp() {
  const { setWallets } = walletsState();

  useSocket();
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const wallets: Wallet[] = (await getWallets()).data;
        if (!wallets.length) return;
        const balances = await getWalletEthBalances(wallets);
        setWallets(
          wallets
            .map((w: Wallet, index: number) => ({
              ...w,
              isSelected: index === 0,
              balance: balances[index],
            }))
            .sort((a, b) => a.wallet_index - b.wallet_index)
        );
      } catch (e) {
        console.log(e);
      }
    };

    fetchWallets();
  }, []);

  return (
    <div className="w-full flex flex-1 gap-4 p-4 bg-background">
      <div className="w-3/5">
        <DexChart />
      </div>
      <div className="w-2/5 flex flex-col gap-4">
        <div className="flex h-3/5 w-full gap-4 justify-between">
          <div className="rounded-t-2xl bg-secondary w-full flex-1">
            <Info />
          </div>
          <div className="rounded-lg bg-secondary w-full flex-1">
            <Order />
          </div>
        </div>
        <div className="flex-1 rounded-lg bg-secondary w-full">
          <Position />
        </div>
      </div>
    </div>
  );
}

export default TradingApp;

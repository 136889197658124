import styled from "styled-components";
import { useState, useEffect } from "react";
import { getPosition } from "apis/user";
import { formatPrice } from "utils/format";

const Styled = {
  PricesArea: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 0 15px;
  `,

  Label: styled.p`
    color: #8e8e8e;
    font-size: 12px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  `,
  Price: styled.p`
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    margin-top: 5px;
  `,
  Export: styled.button`
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    background-color: #8e8e8e;
    border-radius: 10px;
    padding: 0 15px;
    text-transform: uppercase;
  `,
  TableWrapper: styled.div`
    max-height: 250px;
    overflow-y: auto;
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
  `,
  Th: styled.th`
    color: #8e8e8e;
    font-size: 10px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
  `,
  Td: styled.td`
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-family: "Transducer Test";
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid #303030;
    padding: 15px 20px 15px 0;
  `,
};

const Position = () => {
  const [position, setPosition] = useState([]);
  useEffect(() => {
    getPosition()
      .then((res) => setPosition(res.data))
      .catch(() => {});
  }, []);

  console.log(position);

  return (
    <>
      <Styled.PricesArea>
        <div className="flex">
          <div>
            <Styled.Label>UNREALIZED PNL</Styled.Label>
            <Styled.Price>$333.3</Styled.Price>
          </div>
          <div className="ml-3">
            <Styled.Label>HOLDING VALUE</Styled.Label>
            <Styled.Price>$333.3</Styled.Price>
          </div>
        </div>
      </Styled.PricesArea>

      <Styled.TableWrapper>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.Th>Token</Styled.Th>
              <Styled.Th>Token Balance</Styled.Th>
              <Styled.Th>Holding Value</Styled.Th>
              <Styled.Th>Current Price</Styled.Th>
              <Styled.Th>PNL</Styled.Th>
              <Styled.Th></Styled.Th>
            </tr>
          </thead>
          <tbody>
            {position.map((pos) => (
              <tr key={pos.address}>
                <Styled.Td>{pos.name}</Styled.Td>
                <Styled.Td>
                  {(pos.currentBalance / 10 ** pos.decimals).toFixed(3)}
                </Styled.Td>
                <Styled.Td>
                  $
                  {((pos.holdingValue * pos.etherPriceUSD) / 10 ** 18).toFixed(
                    3
                  )}
                </Styled.Td>
                <Styled.Td>${formatPrice(pos.currentPrice)}</Styled.Td>
                <Styled.Td>{pos.unrealizedPNL}</Styled.Td>
                <Styled.Td>View</Styled.Td>
              </tr>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.TableWrapper>
    </>
  );
};

export default Position;

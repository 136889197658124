const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <p className="text-2xl">Oh no!!</p>
      <p className="text-xl my-4">
        You’re either misspelling the URL or requesting a page that's no longer
        here.
      </p>
      <a
        className="text-center rounded-lg px-4 py-2 w-fit bg-orange hover:bg-primary"
        href="/"
      >
        Back To Home
      </a>
    </div>
  );
};

export default NotFound;

type CardProps = {
  containerStyle?: string;
  cardTitle: string;
  buttonText?: string;
  buttonColor?: string;
  hoverLink?: string;
};

const ReferralCard = ({
  containerStyle,
  cardTitle,
  buttonText,
  buttonColor = "bg-transGray",
  hoverLink,
}: CardProps) => {
  return (
    <div
      className={`${containerStyle} rounded-2xl text-center bg-secondary px-2 sm:px-4`}
    >
      <div className="flex text-white items-center justify-center h-24">
        <h1 className="font-bold py-2 sm:py-4 bg-secondary text-white font-transducer m-3 text-md sm:text-xl">
          {cardTitle}
        </h1>
      </div>
      <div className="font-transducer">
        {hoverLink ? (
          <a href={hoverLink} title="How to claim?">
            <button
              className={`w-full text-white font-bold py-3 sm:py-6 rounded-2xl font-transducer mb-4 cursor-pointer ${buttonColor}`}
            >
              {buttonText}
            </button>
          </a>
        ) : (
          <button
            className={`w-full text-white font-bold py-3 sm:py-6 rounded-2xl font-transducer mb-4 cursor-default ${buttonColor}`}
          >
            {buttonText || "N/A"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReferralCard;

import React from "react";

type ToolTipProps = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  description?: string;
  tooltipStyle?: string;
};

const Tooltip = ({
  children,
  title,
  subTitle = "",
  description = "",
  tooltipStyle,
}: ToolTipProps) => {
  if (title === "") return <>{children}</>;
  return (
    <div className="group relative flex justify-center">
      {children}
      <div
        className={
          "absolute top-[115%] bg-secondary border-2 scale-0 rounded-lg p-4 text-xs text-white group-hover:scale-100 flex flex-col items-center z-30 " +
          tooltipStyle
        }
      >
        <p className="text-xs uppercase">{title}</p>
        {subTitle && <span className="text-xs uppercase mt-2">{subTitle}</span>}
        {description && (
          <p className="text-[10px] text-center mt-4">{description}</p>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
